import { all, cancel, fork, take, takeEvery, put, select } from 'redux-saga/effects';
import {
  searchJobAndCompanyNamesApi, searchJobCategoriesApi, searchJobsApi, fetchJobApi, fetchCompanyApi,
  fetchCompanyJobsApi, applyToJobApi, declineJobApi, fetchUserJobsApi,
} from './api';
import { Task } from 'redux-saga';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import {
  SEARCH_JOB_AND_COMPANY_NAMES, SEARCH_JOB_CATEGORIES, SEARCH_JOBS, FETCH_JOB, FETCH_COMPANY,
  FETCH_COMPANY_JOBS, APPLY_TO_JOB, DECLINE_JOB, FETCH_USER_JOBS, SAVE_CURRENT_SEARCH,
} from './constants';
import { setLoading, setCurrentSearch, selectCurrentSearch } from '.';
import { showSuccessToast } from 'components/Toast/actions';

function* searchJobAndCompanyNames({ payload }: { payload: any; type: typeof SEARCH_JOB_AND_COMPANY_NAMES }) {
  yield searchJobAndCompanyNamesApi(payload.name);
}

function* searchJobCategories({ payload }: { payload: any; type: typeof SEARCH_JOB_CATEGORIES }) {
  yield searchJobCategoriesApi(payload.name);
}

function* searchJobs({ payload }: { payload: any; type: typeof SEARCH_JOBS }) {
  yield searchJobsApi(payload);
}

function* saveCurrentSearch({ payload }: { payload: any; type: typeof SAVE_CURRENT_SEARCH }) {
  yield put(setCurrentSearch(payload));
}

function* fetchJob({ payload }: { payload: any; type: typeof FETCH_JOB }) {
  yield fetchJobApi(payload);
}

function* fetchCompany({ payload }: { payload: any; type: typeof FETCH_COMPANY }) {
  yield fetchCompanyApi(payload);
}

function* fetchCompanyJobs({ payload }: { payload: any; type: typeof FETCH_COMPANY_JOBS }) {
  yield fetchCompanyJobsApi(payload);
}

function* fetchUserJobs() {
  yield put(setLoading(true));
  yield fetchUserJobsApi();
  yield put(setLoading(false));
}

const getSearchUrl = (savedSearch) => {
  const { type, name, city, state, country, address , placeId} = savedSearch || {};
  let searchUrl = `/search-results?type=${type}`;
  if (name) {
    searchUrl += `&name=${name}`
  }
  if (city || state || country || placeId) {
    searchUrl += `&placeId=${placeId || ''}&city=${city || ''}&state=${state || ''}&country=${country || ''}&address=${address}`
  }
  return searchUrl;
}

function* applyToJob({ payload }: { payload: any; type: typeof APPLY_TO_JOB }) {
  yield put(setLoading(true));
  yield applyToJobApi(payload);
  // go to saved search
  const savedSearch = yield select(selectCurrentSearch);
  const searchUrl = getSearchUrl(savedSearch);
  yield put(push(searchUrl));
  yield searchJobsApi(savedSearch);
  yield fetchUserJobsApi();
  yield put(setLoading(false));
  yield put(showSuccessToast('You applied to this job.'));
  yield applyToJobApi(payload);
}

function* declineJob({ payload }: { payload: any; type: typeof DECLINE_JOB }) {
  yield put(setLoading(true));
  yield declineJobApi(payload);
  // go to saved search
  const savedSearch = yield select(selectCurrentSearch);
  const searchUrl = getSearchUrl(savedSearch);
  yield put(push(searchUrl));
  yield searchJobsApi(savedSearch);
  yield fetchUserJobsApi();
  yield put(setLoading(false));
  yield put(showSuccessToast('You declined this job.'));
}

function* watchCompaniesAndJobs() {
  yield takeEvery(SEARCH_JOB_AND_COMPANY_NAMES, searchJobAndCompanyNames);  
  yield takeEvery(SEARCH_JOB_CATEGORIES, searchJobCategories);
  yield takeEvery(SEARCH_JOBS, searchJobs);
  yield takeEvery(FETCH_JOB, fetchJob);
  yield takeEvery(FETCH_COMPANY, fetchCompany);
  yield takeEvery(FETCH_COMPANY_JOBS, fetchCompanyJobs);
  yield takeEvery(FETCH_USER_JOBS, fetchUserJobs);
  yield takeEvery(APPLY_TO_JOB, applyToJob);
  yield takeEvery(DECLINE_JOB, declineJob);
  yield takeEvery(SAVE_CURRENT_SEARCH, saveCurrentSearch);
}

export function* companiesAndJobsSaga() {
  const watchers: Task[] = yield all([fork(watchCompaniesAndJobs)]);

  yield take(LOCATION_CHANGE);
  yield watchers.map(watcher => cancel(watcher));
}
