import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface companiesAndJobsInterface {
  jobNames: any[];
  jobCategories: any[];
  companies: any[];
  jobs: any;
  companyJobs: any[];
  currentJob: any;
  currentCompany: any;
  userJobs: any[];
  loading: boolean;
  currentSearch: any;
}

const initialState: companiesAndJobsInterface = {
  jobNames: [],
  jobCategories: [],
  companies: [],
  jobs: null,
  companyJobs: [],
  currentJob: null,
  currentCompany: null,
  userJobs: [],
  loading: false,
  currentSearch: null,
};

export const companiesAndJobsSlice = createSlice({
  name: 'companiesAndJobs',
  initialState,
  reducers: {
    setJobNames: (state, action: PayloadAction<any[]>) => {
      state.jobNames = action.payload;
    },
    setJobCategories: (state, action: PayloadAction<any[]>) => {
      state.jobCategories = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<any[]>) => {
      state.currentSearch = action.payload;
    },
    setCompanies: (state, action: PayloadAction<any[]>) => {
      state.companies = action.payload;
    },
    setJobs: (state, action: PayloadAction<any[]>) => {
      state.jobs = action.payload;
    },
    setCompanyJobs: (state, action: PayloadAction<any[]>) => {
      state.companyJobs = action.payload;
    },
    setCurrentJob: (state, action: PayloadAction<any[]>) => {
      state.currentJob = action.payload;
    },
    setCurrentCompany: (state, action: PayloadAction<any[]>) => {
      state.currentCompany = action.payload;
    },
    setUserJobs: (state, action: PayloadAction<any>) => {
      state.userJobs = action.payload.jobs;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    updateValue: (state, action: PayloadAction<{ key: string; value: string }>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
