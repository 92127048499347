import { createSelector } from '@reduxjs/toolkit';
import { authSlice } from './authSlice';
import { authenticationFlow, logoutFlow } from './sagas';
import { RootState } from 'App/App';

export const { setIsAuth, setAuthUser } = authSlice.actions;

export const { reducer } = authSlice;

export { authenticationFlow, logoutFlow };

export const selectPathName = (state: RootState) => state.router.location.pathname;

export const getFromPathName = (state: RootState) => state.router.location.state?.from?.pathname || '/';

export const selectIsAuth = (state: RootState) => state.auth.isAuth;

const getToken = (state: RootState) => state.auth?.authUser?.token;

const getUserEmail = (state: RootState) => state.auth?.authUser?.email;

export const selectAuthUser = (state: RootState) => state.auth?.authUser;

export const selectToken = createSelector(getToken, token => token);

export const selectUserEmail = createSelector(getUserEmail, email => email);