import { ExitToApp, LiveHelp } from '@material-ui/icons';
import { InputBase, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography } from '@material-ui/core';
import React, { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';

import LanguageIcon from '@material-ui/icons/Language';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import styles from './NavbarFooter.module.scss';
import { useAppDispatch } from 'App/hooks';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import { Link } from 'react-router-dom';

interface NavbarFooterProps {
  isAuth: boolean;
  handleSignOut?: () => void;
  onClose?: () => void;
  setShowLangSelector?: Dispatch<SetStateAction<boolean>>;
}

interface language {
  value: string;
  name: string;
}

const useStyles = makeStyles(theme => ({
  input: {
    cursor: 'pointer',
  },
}));

const NavbarFooter: FC<NavbarFooterProps> = ({ isAuth, handleSignOut, onClose, setShowLangSelector }) => {
  const [languages, setLanguages] = useState<language[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<language | undefined>({ value: 'en', name: 'English' });
  const [showMobileLangSelector, setShowMobileLangSelector] = useState(false);
  const [isMobileFormat, setIsMobileFormat] = useState<boolean>(window.innerWidth < 959);

  const localStorageLanguage = localStorage.getItem('language');
  const dispatch = useAppDispatch();
  const { t } = useTranslation('mainNavbar');
  const classes = useStyles();

  const onClickHandler = href => {
    onClose && onClose();
    dispatch(push(href));
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobileFormat(window.innerWidth <= 959);
    };

    window.addEventListener('resize', handleWindowResize);
  }, []);

  async function fetchLanguagesList() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API}/career/languages/loadedLanguages`);
      const list = await response.json();
      setLanguages(list);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchLanguagesList();
  }, []);

  useEffect(() => {
    setCurrentLanguage(languages.find(l => l.value === (localStorageLanguage || localStorage.getItem('i18nextLng'))));
  }, [languages, localStorageLanguage]);

  return (
    <>
      <div
        className={`${styles.navbarFooter} ${isMobileFormat ? styles.MobileFooter : ''}`}
        aria-label="navigation bar menu footer">
        {/* {isAuth && (
              <MenuItem className={styles.navbarFooterItem} onClick={handleSignOut}>
                <ListItemIcon className={styles.FooterIcon}>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={t('navbarFooter.signOut')} />
              </MenuItem>
            )} */}
        <Link to={'/help/career'} className={styles.navbarFooterItem}>
          <ListItemIcon className={styles.FooterIcon}>
            <LiveHelp />
          </ListItemIcon>
          {/* <ListItemText className={styles.menuFooterText}>{t('navbarFooter.helpSupport')}</ListItemText> */}
          <Typography
            variant="body2"
            className={isMobileFormat ? styles.menuFooterTextMobile : styles.menuFooterTextDesktop}>
            Help & Support
          </Typography>
        </Link>

        <ListItem className={styles.navbarFooterItem}>
          <ListItemIcon className={styles.FooterIcon}>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText>
            {showMobileLangSelector && isMobileFormat && (
              <LanguageSelector setShowMobileLangSelector={setShowMobileLangSelector} onClose={onClose} />
            )}
            <InputBase
              value={currentLanguage?.name}
              placeholder={'Language'}
              className={`${styles.InputBase} ${isMobileFormat ? styles.InputBaseMobile : styles.InputBaseDesktop}`}
              onClick={() => {
                setShowLangSelector && setShowLangSelector(true);
                setShowMobileLangSelector(true);
                !isMobileFormat && onClose && onClose();
              }}
              inputProps={{ readOnly: true }}
            />
          </ListItemText>
        </ListItem>
        <div className={styles.TermsPrivacyWrapper}>
          <Link to={'/terms'}>
            <Typography className={styles.TermsAndCopyText}>{t('navbarFooter.terms')}</Typography>
          </Link>
          &nbsp;&middot;&nbsp;
          <Link to={'/privacy'}>
            <Typography className={styles.TermsAndCopyText}>{t('navbarFooter.privacy')}</Typography>
          </Link>
        </div>

        <div className={styles.copyRightWrapper}>
          <Typography className={styles.TermsAndCopyText}>
            Noviopus &nbsp;&copy;&nbsp;
            {new Date().getFullYear()}
          </Typography>
        </div>
        {/* 
        <div>
          <ListItem className={clsx(styles.navbarFooterItem, styles.terms)}>
            <ListItemText>
              <span onClick={() => onClickHandler('/terms')}>{t('navbarFooter.terms')}</span>
              &nbsp;&middot;&nbsp;
              <span onClick={() => onClickHandler('/privacy')}>{t('navbarFooter.privacy')}</span>
            </ListItemText>
          </ListItem>
          <ListItem className={clsx(styles.navbarFooterItem, styles.terms)}>
            <ListItemText>
              <span>Noviopus</span>
              &nbsp;&copy;&nbsp;
              {new Date().getFullYear()}
            </ListItemText>
          </ListItem>
        </div> */}
        {/* version list item */}
        {/* <ListItem className={clsx(styles.navbarFooterItem, styles.terms)} >
              <ListItemText>
                <span>{process.env.VERSION}</span>
              </ListItemText>
            </ListItem> */}
      </div>
    </>
  );
};

export default NavbarFooter;
