import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// import { Comment, CommentsState } from './types';
import { CommentsState } from './types';
// import Icon from '../../../assets/images/user-ava.png';


// const initialComment: Comment = {
//  albumId : "6411e536bd67220025443784",
//  content: "this is initial test comment from local",
//  createdAt: new Date(),
//  hidden: false,
//  id: "6480571ae45146af36901284",
//  reported: 0,
//  updatedAt:new Date(),
//  userId: "64009cc66f619f001a884b2d"
// };

const initialState: CommentsState = {
  // comments: [initialComment],
  comments: [],
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: (state, action: PayloadAction<any>) => {
      state.comments = action.payload;
    },
  },
});
