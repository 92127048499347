import CloseIcon from '@material-ui/icons/Close';
import React, { useRef } from 'react';
import { selectDirection } from '../../App/features/direction/directionSlice';
import { useAppSelector } from '../../App/hooks';
import styles from './CustomChip.module.scss';

interface CustomChipPropTypes {
  key: string;
  minWidth?: number;
  item: { name: string; checked: boolean; icon?: any };
  withCloseBtn?: boolean;
  checkboxClickable?: boolean;
  onClickInput?: () => void;
  onClickClose?: () => void;
  clearAll?: () => void;
}

const CustomChip: React.FC<CustomChipPropTypes> = ({
  item,
  withCloseBtn = false,
  // minWidth = 40,
  checkboxClickable = true,
  onClickInput,
  onClickClose,
}): JSX.Element => {
  const input = useRef(null);
  const direction = useAppSelector(selectDirection);

  const handleClickClose = event => {
    event.stopPropagation();
    if (onClickClose) {
      onClickClose();
    }
  };

  return (
    <div
      style={{ cursor: onClickInput ? 'pointer' : 'unset' }}
      className={item.checked ? `${styles.button} ${styles.checked}` : `${styles.button} ${styles.unchecked}`}
      onClick={onClickInput}>
      <input
        className={styles.uiCheckbox}
        ref={input}
        type="checkbox"
        id={`checkbox-${item.name}`}
        checked={item.checked}
        readOnly={!checkboxClickable}
      />
      <div className={styles.icon}>{item.icon}</div>
      <label className={styles.text} style={{ cursor: onClickInput ? 'pointer' : 'unset' }}>
        {item.name}
      </label>
      {withCloseBtn && (
        <div
          className={styles.closeButton}
          onClick={handleClickClose}
          style={direction === 'rtl' ? { right: 'unset', left: '11px' } : undefined}>
          <CloseIcon style={{ fontSize: 10 }} />
        </div>
      )}
    </div>
  );
};

export default CustomChip;
