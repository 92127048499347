/**
 * This will setup the i18n language files and locale data for your app.
 */
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LngDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const options = {
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  defaultNS: 'translations',
  debug: false,
  react: {
    wait: true,
    useSuspense: false,
    defaultTransParent: 'div',
  },

  // resources: {
  //   en: {
  //     translations: require('locales/en/translations.json'),
  //     createAccountTranslate: require('locales/en/createAccountTranslate.json'),
  //     loginTranslate: require('locales/en/loginTranslate.json'),
  //     checkEmailTranslate: require('locales/en/checkEmailTranslate.json'),
  //     profileTranslate: require('locales/en/profileTranslate.json'),
  //     educationTranslate: require('locales/en/educationTranslate.json'),
  //     workExperienceTranslate: require('locales/en/workExperienceTranslate.json'),
  //     skillsTranslate: require('locales/en/skillsTranslate.json'),
  //     languagesTranslate: require('locales/en/languagesTranslate.json'),
  //     jobSearchTranslate: require('locales/en/jobSearchTranslate.json'),
  //     workplaceTranslate: require('locales/en/workplaceTranslate.json'),
  //     socialTranslate: require('locales/en/socialAccountsTranslate.json'),
  //     addConnectionsTranslate: require('locales/en/addConnectionsTranslate.json'),
  //     personalInterestsTranslate: require('locales/en/personalInterestsTranslate.json'),
  //     languageRegionTranslate: require('locales/en/languageRegionTranslate.json'),
  //     accountStatusTranslate: require('locales/en/accountStatusTranslate.json'),
  //     accountSecurityTranslate: require('locales/en/accountSecurityTranslate.json'),
  //     termsOfUseTranslate: require('locales/en/termsOfUseTranslate.json'),
  //     privacyPolicyTranslate: require('locales/en/privacyPolicyTranslation.json'),
  //     careerFaqTranslate: require('locales/en/careerFaqTranslate.json'),
  //     businessFaqTranslate: require('locales/en/businessFaqTranslate.json'),
  //     helpCentreCareerTranslate: require('locales/en/helpCentreCareerTranslate.json'),
  //     helpCentreBusinessTranslate: require('locales/en/helpCentreBusinessTranslate.json'),
  //     usingNoviopusCareerTranslate: require('locales/en/usingNoviopusCareerTranslate.json'),
  //     usingNoviopusBusinessTranslate: require('locales/en/usingNoviopusBusinessTranslate.json'),
  //     jobSearchPageTranslate: require('locales/en/jobSearchPageTranslate.json'),
  //     uploadCvTranslate: require('locales/en/uploadCvTranslate.json'),
  //     companyAboutTranslate: require('locales/en/companyAboutTranslate.json'),
  //     companyNavigation: require('locales/en/companyNavigation.json'),
  //     companySidebar: require('locales/en/companySidebar.json'),
  //     companyJobPageTranslate: require('locales/en/companyJobPageTranslate.json'),
  //     publicJobTranslate: require('locales/en/publicJobTranslate.json'),
  //     publicJobSidebar: require('locales/en/publicJobSidebar.json'),
  //     navigation: require('locales/en/navigation.json'),
  //     sidebar: require('locales/en/sidebar.json'),
  //     mainNavbar: require('locales/en/mainNavbar.json'),
  //   },
  //   he: {},
  // },
};

i18n
  .use(LngDetector)
  .use(intervalPlural)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      if (localStorage.getItem('translation')) {

        if(JSON.parse(localStorage.getItem('translation') || '{}')[language]){
          callback(null, JSON.parse(localStorage.getItem('translation') || '{}')[language][namespace]);
        } else{
          fetch(`${process.env.REACT_APP_BASE_API}/career/language/${language}`)
          .then(response => response.json())
          .then(resource => {

            //Insert the new language 
            let existsLangs = JSON.parse(localStorage.getItem('translation') || '{}')
            existsLangs[language] = resource[language]
            localStorage.setItem('translation', JSON.stringify(existsLangs));
            callback(null, resource[language][namespace]);
            
          })
          .catch(error => {
            callback(error, 'error');
          });
        }
       

      } else {        
        fetch(`${process.env.REACT_APP_BASE_API}/career/languages/cache`)
          .then(response => response.json())
          .then(resources => {
            localStorage.setItem('translation', JSON.stringify(resources));
            callback(null, resources[language][namespace]);
          })
          .catch(error => {
            callback(error, 'error');
          });
      }
    }),
  )
  .init(options as any);

export default i18n;
