import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'App/hooks';
import FormTextInput from 'components/FormTextInput';
import SelectInput from 'components/SelectInput';
import usingNoviopus from './images/usingNoviopus.svg';
import faq from './images/faq.svg';
import contact from './images/contact.svg';
import { useStyles } from './styles';
import styles from './HelpCenterCareer.module.scss';
import { useTranslation } from 'react-i18next';
import { selectDirection } from '../App/features/direction/directionSlice';

const HelpCenterCareer: React.FC = () => {
  const [subject, setSubject] = useState<string>('account');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const direction = useAppSelector(selectDirection);

  const { t } = useTranslation('helpCentreCareerTranslate');

  const { control, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      email: '',
      phoneNumber: '',
      firstname: '',
      lastname: '',
      subject: 'account',
      message: '',
    },
    mode: 'all',
  });

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeSubject = event => {
    setSubject(event.target.value);
    setValue('subject', event.target.value);
  };

  const onSubmit = () => {
    if (!isValid) return;
    console.log(dispatch);
    setIsSubmitted(true);
    // how do I submit it?
  };

  const classes = useStyles();

  const faqLinks = [
    { href: '/help/career/faq?id=new-account', title: t('faqLinks.createAccount') },
    { href: '/help/career/faq?id=confirm-email', title: t('faqLinks.confirmEmail') },
    { href: '/help/career/faq?id=confirm-email-issues', title: t('faqLinks.noConfirmation') },
    { href: '/help/career/faq?id=sign-in', title: t('faqLinks.signIn') },
    { href: '/help/career/faq?id=reset-password', title: t('faqLinks.resetPassword') },
    { href: '/help/career/faq?id=job-search', title: t('faqLinks.searchJobs') },
    { href: '/help/career/faq?id=apply-decline-jobs', title: t('faqLinks.applyJobs') },
    { href: '/help/career/faq?id=personal-profile', title: t('faqLinks.editPersonalProfile') },
    { href: '/help/career/faq?id=career-profile', title: t('faqLinks.editCareerProfile') },
    { href: '/help/career/faq?id=job-preferences', title: t('faqLinks.jobPreferences') },
    { href: '/help/career/faq?id=applications', title: t('faqLinks.applications') },
    { href: '/help/career/faq?id=workplace-preferences', title: t('faqLinks.workplacePreferences') },
    { href: '/help/career/faq?id=connections', title: t('faqLinks.addConnections') },
    { href: '/help/career/faq?id=personal-interests', title: t('faqLinks.addInterests') },
    { href: '/help/career/faq?id=change-password', title: t('faqLinks.changePassword') },
  ];

  const subjectOptions = [
    { value: 'account', name: t('subjectOptions.account') },
    { value: 'career', name: t('subjectOptions.career') },
    { value: 'pages', name: t('subjectOptions.pages') },
    { value: 'messages', name: t('subjectOptions.messages') },
    { value: 'events', name: t('subjectOptions.events') },
    { value: 'support', name: t('subjectOptions.support') },
  ];

  return (
    <div className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>{t('helpCenter')}</title>
      </Helmet>
      <h1>{t('careerHelpCenter')}</h1>
      <div className={styles.content}>
        <div id="help" className={styles.card}>
          <h2>{t('usingNoviopus')}</h2>
          <span>{t('findAnswer')}</span>
          <img
            src={usingNoviopus}
            className={styles.usingNoviopusImg}
            style={direction === 'rtl' ? { transform: 'scaleX(-1)', right: '2rem', left: 0 } : undefined}
            alt="usingNoviopus"
          />
          <Link to="/help/career/using-noviopus" className={styles.link}>
            <Button variant="contained" className={styles.btn}>
              {t('usingNoviopus')}
            </Button>
          </Link>
        </div>
        <div className={styles.card}>
          <h2>{t('frequentlyAskedQuestions')}</h2>
          <span>{t('haveQuestions')}</span>
          <img
            src={faq}
            className={styles.faqImg}
            style={direction === 'rtl' ? { transform: 'scaleX(-1)', right: '2rem', left: 0 } : undefined}
            alt="faq"
          />
          <ul className={styles.faqList}>
            {faqLinks.map((link, i) => (
              <li>
                <Link key={i} to={link.href} className={styles.faqLink}>
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link to="/help/career/faq" className={styles.link}>
            <Button variant="contained" className={styles.btn}>
              {t('FAQ')}
            </Button>
          </Link>
        </div>
        {!isSubmitted ? (
          <div className={styles.card} id="contact-us">
            <h2>{t('contactUs')}</h2>
            <span>{t('tellHowWeCanHelp')}</span>
            <img
              src={contact}
              className={styles.contactImg}
              style={direction === 'rtl' ? { transform: 'scaleX(-1)', right: '2rem', left: 0 } : undefined}
              alt="contact"
            />
            <form className={styles.form}>
              <Grid container item sm={12} spacing={2} style={{ alignSelf: 'center' }}>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('emailAddress')}</Box>
                  <FormTextInput name="email" control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('phoneNumber')}</Box>
                  <FormTextInput name="phoneNumber" rules={{ required: false }} control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('firstName')}</Box>
                  <FormTextInput name="firstname" control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('lastName')}</Box>
                  <FormTextInput name="lastname" control={control} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={styles.fieldLabel}>{t('subject')}</Box>
                  <SelectInput propValue={subject} handleChange={handleChangeSubject} options={subjectOptions} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={styles.fieldLabel}>{t('howWeCanHelp')}</Box>
                  <TextField className={classes.textarea} multiline name="message" rows={4} />
                </Grid>
              </Grid>
              <div className={styles.btnContainer}>
                <Button type="submit" variant="contained" className={styles.btn}>
                  {t('submit')}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className={styles.card}>
            <span>{t('thanksForContacting')}</span>
          </div>
        )}
      </div>
      <Link to="/help/business" className={styles.businessLink}>
        {t('businessHelpCenter')}
      </Link>
    </div>
  );
};

export default HelpCenterCareer;
