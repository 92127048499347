import { RootState } from 'App/App';
import { commentsSlice } from './commentsSlice';
import { watchUserCommentsSaga } from './sagas';

export const {
  setComments,
} = commentsSlice.actions;

export const reducer = commentsSlice.reducer;

export { watchUserCommentsSaga as saga };

export const selectComment = (state: RootState) => state.comments;
