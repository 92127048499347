import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import styles from './PrivacyPolicy.module.scss';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
  const [markdownContent, setMarkdownContent] = useState<string>('');
  const localLanguage = localStorage.getItem('language');

  const { t } = useTranslation('privacyPolicyTranslate');

  const fetchMarkdownContent = (source, body) => {
    fetch(source, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
      });
  };

  useEffect(() => {
    fetchMarkdownContent(`${process.env.REACT_APP_BASE_API}/career/languages/getMD`, {
      language: localLanguage,
      namespace: `privacy_${localLanguage}`,
    });
  }, [localLanguage]);

  if (!markdownContent) return null;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{t('privacyPolicy')}</title>
      </Helmet>
      <h1 className={styles.title}>{t('noviopusPolicy')}</h1>
      <div className={styles.content}>
        <ReactMarkdown children={markdownContent} />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
