interface Language {
    value: string;
    name: string;
    direction: string;
  }
  
  const languageToCountryCode: { [key: string]: string } = {
    ar: 'sa', // Arabic to Saudi Arabia
    de: 'de', // German to Germany
    en: 'gb', // English to United Kingdom
    he: 'il', // Hebrew to Israel
    es: 'es', // Spanish to Spain
    fr: 'fr', // French to France
    ko: 'kr', // Korean to South Korea
    ru: 'ru', // Russian to Russia
    tl: 'ph', // Tagalog to Philippines
    vi: 'vn', // Vietnamese to Vietnam
    zh: 'cn', // Chinese to China
    it: 'it', // Italian to Italy
    af: 'za', // Afrikaans to South Africa
    sq: 'al', // Albanian to Albania
    am: 'et', // Amharic to Ethiopia
    hy: 'am', // Armenian to Armenia
    as: 'in', // Assamese to India
    az: 'az', // Azerbaijani to Azerbaijan
    eu: 'es', // Basque to Spain
    be: 'by', // Belarusian to Belarus
    bn: 'bd', // Bengali to Bangladesh
    bs: 'ba', // Bosnian to Bosnia and Herzegovina
    bg: 'bg', // Bulgarian to Bulgaria
    my: 'mm', // Burmese to Myanmar
    ca: 'ad', // Catalan to Andorra
    hr: 'hr', // Croatian to Croatia
    cs: 'cz', // Czech to Czech Republic
    da: 'dk', // Danish to Denmark
    dv: 'mv', // Divehi to Maldives
    nl: 'nl', // Dutch to Netherlands
    et: 'ee', // Estonian to Estonia
    mk: 'mk', // Macedonian to North Macedonia
    fa: 'ir', // Persian to Iran
    fi: 'fi', // Finnish to Finland
    gd: 'gb', // Scottish Gaelic to United Kingdom
    gl: 'es', // Galician to Spain
    ka: 'ge', // Georgian to Georgia
    el: 'gr', // Greek to Greece
    gn: 'py', // Guarani to Paraguay
    gu: 'in', // Gujarati to India
    hi: 'in', // Hindi to India
    hu: 'hu', // Hungarian to Hungary
    is: 'is', // Icelandic to Iceland
    id: 'id', // Indonesian to Indonesia
    ja: 'jp', // Japanese to Japan
    kn: 'in', // Kannada to India
    kk: 'kz', // Kazakh to Kazakhstan
    km: 'kh', // Khmer to Cambodia
    lo: 'la', // Lao to Laos
    la: 'va', // Latin to Vatican City
    lv: 'lv', // Latvian to Latvia
    lt: 'lt', // Lithuanian to Lithuania
    ms: 'my', // Malay to Malaysia
    ml: 'in', // Malayalam to India
    mi: 'nz', // Maori to New Zealand
    mr: 'in', // Marathi to India
    mn: 'mn', // Mongolian to Mongolia
    ne: 'np', // Nepali to Nepal
    no: 'no', // Norwegian (Bokmal) to Norway
    or: 'in', // Odia (Oriya) to India
    pl: 'pl', // Polish to Poland
    pt: 'pt', // Portuguese to Portugal
    pa: 'in', // Punjabi to India
    ro: 'ro', // Romanian to Romania
    sa: 'in', // Sanskrit to India
    sr: 'rs', // Serbian to Serbia
    sd: 'pk', // Sindhi to Pakistan
    si: 'lk', // Sinhala to Sri Lanka
    sk: 'sk', // Slovak to Slovakia
    sl: 'si', // Slovenian to Slovenia
    so: 'so', // Somali to Somalia
    sw: 'tz', // Swahili to Tanzania
    sv: 'se', // Swedish to Sweden
    tg: 'tj', // Tajik to Tajikistan
    ta: 'in', // Tamil to India
    te: 'in', // Telugu to India
    th: 'th', // Thai to Thailand
    ts: 'za', // Tsonga to South Africa
    tr: 'tr', // Turkish to Turkey
    tk: 'tm', // Turkmen to Turkmenistan
    uk: 'ua', // Ukrainian to Ukraine
    ur: 'pk', // Urdu to Pakistan
    uz: 'uz', // Uzbek to Uzbekistan
    cy: 'gb', // Welsh to United Kingdom
    xh: 'za', // Xhosa to South Africa
    yi: 'il', // Yiddish to Israel
    zu: 'za', // Zulu to South Africa
  };
  
  export const mapLanguageToCountryCode = (language: Language): string => {
    return languageToCountryCode[language.value] || 'default';
  };
  