import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { useAppDispatch } from 'App/hooks';
import FormTextInput from 'components/FormTextInput';
import SelectInput from 'components/SelectInput';
import usingNoviopus from './images/usingNoviopus.svg';
import faq from './images/faq.svg';
import contact from './images/contact.svg';
import { useStyles } from './styles';
import styles from './HelpCenterBusiness.module.scss';
import { useTranslation } from 'react-i18next';

const HelpCenterBusiness: React.FC = () => {
  const [subject, setSubject] = useState<string>('support');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('helpCentreBusinessTranslate');
  const { control, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      email: '',
      phoneNumber: '',
      firstname: '',
      lastname: '',
      subject: 'support',
      message: '',
    },
    mode: 'all',
  });

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeSubject = event => {
    setSubject(event.target.value);
    setValue('subject', event.target.value);
  };

  const onSubmit = () => {
    if (!isValid) return;
    console.log(dispatch);
    setIsSubmitted(true);
    // how do I submit it?
  };

  const classes = useStyles();

  const faqLinks = [
    { href: '/help/business/faq?id=new-account', title: t('faqLinks.createAccount') },
    { href: '/help/business/faq?id=confirm-email', title: t('faqLinks.confirmEmail') },
    { href: '/help/business/faq?id=confirm-email-issues', title: t('faqLinks.noConfirmation') },
    { href: '/help/business/faq?id=sign-in', title: t('faqLinks.signIn') },
    { href: '/help/business/faq?id=reset-password', title: t('faqLinks.resetPassword') },
    { href: '/help/business/faq?id=create-company', title: t('faqLinks.createCompany') },
    { href: '/help/business/faq?id=create-job', title: t('faqLinks.createJob') },
    { href: '/help/business/faq?id=manage-job', title: t('faqLinks.manageHiring') },
    { href: '/help/business/faq?id=account-settings', title: t('faqLinks.editAccountSettings') },
    { href: '/help/business/faq?id=change-password', title: t('faqLinks.changePassword') },
    { href: '/help/business/faq?id=languages', title: t('faqLinks.changeLanguage') },
    { href: '/help/business/faq?id=suspend-account', title: t('faqLinks.suspendAccount') },
    { href: '/help/business/faq?id=delete-account', title: t('faqLinks.deleteAccount') },
  ];

  const subjectOptions = [
    { value: 'support', name: t('subjectOptions.support') },
    { value: 'service', name: t('subjectOptions.service') },
    { value: 'financial', name: t('subjectOptions.financial') },
    { value: 'report', name: t('subjectOptions.report') },
  ];

  return (
    <div className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>{t('helpCenter')}</title>
      </Helmet>
      <h1>{t('businessHelpCenter')}</h1>
      <div className={styles.content}>
        <div className={styles.card}  id="help">
          <h2>{t('usingNoviopus')}</h2>
          <span>{t('findAnswer')}</span>
          <img src={usingNoviopus} className={styles.usingNoviopusImg} alt="usingNoviopus" />
          <Link to="/help/business/using-noviopus" className={styles.link}>
            <Button variant="contained" className={styles.btn}>
              {t('usingNoviopus')}
            </Button>
          </Link>
        </div>
        <div className={styles.card}>
          <h2>{t('frequentlyAskedQuestions')}</h2>
          <span>{t('haveQuestions')}</span>
          <img src={faq} className={styles.faqImg} alt="faq" />
          <ul className={styles.faqList}>
            {faqLinks.map((link, i) => (
              <li>
                <Link key={i} to={link.href} className={styles.faqLink}>
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link to="/help/business/faq" className={styles.link}>
            <Button variant="contained" className={styles.btn}>
              {t('FAQ')}
            </Button>
          </Link>
        </div>
        {!isSubmitted ? (
          <div className={styles.card} id="contact-us">
            <h2>{t('contactUs')}</h2>
            <span>{t('tellHowWeCanHelp')}</span>
            <img src={contact} className={styles.contactImg} alt="contact" />
            <form className={styles.form}>
              <Grid container item sm={12} spacing={2} style={{ alignSelf: 'center' }}>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('emailAddress')}</Box>
                  <FormTextInput name="email" control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('phoneNumber')}</Box>
                  <FormTextInput name="phoneNumber" rules={{ required: false }} control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('firstName')}</Box>
                  <FormTextInput name="firstname" control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={styles.fieldLabel}>{t('lastName')}</Box>
                  <FormTextInput name="lastname" control={control} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={styles.fieldLabel}>{t('subject')}</Box>
                  <SelectInput propValue={subject} handleChange={handleChangeSubject} options={subjectOptions} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={styles.fieldLabel}>{t('howWeCanHelp')}</Box>
                  <TextField className={classes.textarea} multiline name="message" rows={4} />
                </Grid>
              </Grid>
              <div className={styles.btnContainer}>
                <Button type="submit" variant="contained" className={styles.btn}>
                  {t('submit')}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className={styles.card}>
            <span>{t('thanksForContacting')}</span>
          </div>
        )}
      </div>
      <Link to="/help/career" className={styles.careerLink}>
        {t('careerHelpCenter')}
      </Link>
    </div>
  );
};

export default HelpCenterBusiness;
