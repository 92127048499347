import { RootState } from 'App/App';
import { createSelector } from '@reduxjs/toolkit';
import { onBoardingStageInterface } from './types';
import { onBoardingStageSlice } from './onBoardingStageSlice';
import { stageSaga } from './sagas';

export const { setStage, setInformation, setOnBoarded, checkStage, checkSuccess } = onBoardingStageSlice.actions;

export const { reducer } = onBoardingStageSlice;

export { stageSaga as saga };

export const selectStage = (state: RootState) => state.stage;

export const selectInformation = (state: RootState) => state.information;

export const selectOnBoarded = (state: RootState) => state.stage.onBoarded;

export const getOnBoardingRedirectUrl = createSelector(selectStage, (currentStage: onBoardingStageInterface) => {
  switch (`${currentStage.stage}`) {
    case '0':
      return '/account/profile';
    case '1':
      return '/account/education';
    case '2':
      return '/account/experience';
    case '3':
      return '/account/skills';
    case '4':
      return '/account/languages';
    case '5':
      return '/account/job-search';
    case '6':
      return '/account/workplace';
    case '7':
      return '/account/add-contacts';
    case '8':
      return '/account/interests';
    case '9':
      return '/';
    default:
      return null;
  }
});
