import { RootState } from 'App/App';
import { companiesAndJobsSlice } from './companiesAndJobsSlice';
import { companiesAndJobsSaga } from './sagas';

export const { reducer } = companiesAndJobsSlice;

export { companiesAndJobsSaga as saga };

export const {
  setJobNames, setJobCategories, setCompanies, setJobs, setCompanyJobs, setCurrentJob,
  setCurrentCompany, setUserJobs, updateValue, setLoading, setCurrentSearch,
} = companiesAndJobsSlice.actions;

export const selectJobNames = (state: RootState) => state.companiesAndJobs?.jobNames;
export const selectJobCategories = (state: RootState) => state.companiesAndJobs?.jobCategories;
export const selectCompanies = (state: RootState) => state.companiesAndJobs?.companies;
export const selectJobs = (state: RootState) => state.companiesAndJobs?.jobs;
export const selectCompanyJobs = (state: RootState) => state.companiesAndJobs?.companyJobs;
export const selectCurrentJob = (state: RootState) => state.companiesAndJobs?.currentJob;
export const selectCurrentCompany = (state: RootState) => state.companiesAndJobs?.currentCompany;
export const selectUserJobs = (state: RootState) => state.companiesAndJobs?.userJobs;
export const selectLoading = (state: RootState) => state.companiesAndJobs?.loading;
export const selectCurrentSearch = (state: RootState) => state.companiesAndJobs?.currentSearch;