import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(theme => {
  return {
    textarea: {
      width: '100%',
      height: 140,
      backgroundColor: theme.input.backgroundColor,

      '& .MuiInput-root': {
        height: '100%',
        padding: '1rem',
        letterSpacing: '0.3px',
      },

      '& .MuiInput-inputMultiline': {
        height: '100%',
        fontFamily: 'Roboto-Light, Arial, sans-serif',
        fontSize: 14,
      },
    },
  };
});
