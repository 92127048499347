import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import styles from './UsingNoviopus.module.scss';

const UsingNoviopus: React.FC = () => {
  const [markdownContent, setMarkdownContent] = useState<string>('');
  const localLanguage = localStorage.getItem('language');
  const { t } = useTranslation('usingNoviopusCareerTranslate');
  const { search } = useLocation();

  const ids = ['general', 'create-an-account', 'create-your-profile', 'career', 'settings'];

  function setIds(root: HTMLElement | null): void {
    if (root === null) return;
    const setId = (el, id) => (el.id = id);
    Array.from(root.querySelectorAll('h1')).forEach((el, i) => setId(el, ids[i]));
  }

  const fetchMarkdownContent = async (source, body) => {
    await fetch(source, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
      });
    setIds(document.querySelector(`.${styles.content}`));
  };

  useEffect(() => {
    fetchMarkdownContent(`${process.env.REACT_APP_BASE_API}/career/languages/getMD`, {
      language: localLanguage,
      namespace: `help_career_${localLanguage}`,
    });
  }, [localLanguage]);

  return (
    <div className={styles.container}>
      <h1>{t('usingNoviopus')}</h1>
      <div className={styles.content}>
        <ReactMarkdown children={markdownContent} />
      </div>
    </div>
  );
};

export default UsingNoviopus;
