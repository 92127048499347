import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInterface } from './types';

interface AuthInterface {
  isAuth: boolean;
  authUser: UserInterface | null;
}

export const initialState: AuthInterface = {
  isAuth: false,
  authUser: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setAuthUser: (state, action: PayloadAction<UserInterface | null>) => {
      state.authUser = action.payload;
    },
  },
});
