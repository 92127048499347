import { RootState } from 'App/App';
import { createSelector } from '@reduxjs/toolkit';
import { profileSlice } from './profileSlice';
import { watchUserProfileSaga } from './sagas';

export const { setUserProfile, updateValue, updateLocation } = profileSlice.actions;

export const { reducer } = profileSlice;

export { watchUserProfileSaga as saga };

export const selectUserProfile = (state: RootState) => state.profile?.initData;
export const selectProfileForm = (state: RootState) => state.profile.formData;

export const selectLocation = createSelector(selectProfileForm, profile => profile.location);
export const selectInitProfile = createSelector(selectUserProfile, initData => initData);
