import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { onBoardingStageInterface } from './types';

const initialState: onBoardingStageInterface = { stage: null, information: null, onBoarded: true };

const onBoardingStageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    setStage: (state, action: PayloadAction<onBoardingStageInterface>) => {
      state.stage = action.payload.stage;
    },
    setInformation: (state, action: PayloadAction<onBoardingStageInterface>) => {
      state.information = action.payload.information;
    },
    setOnBoarded: (state, action: PayloadAction<{ onBoarded: boolean }>) => {
      state.onBoarded = action.payload.onBoarded;
    },
    checkStage: () => {},
    checkSuccess: () => {},
  },
});

export { onBoardingStageSlice };
