import { all, put, race, take, takeEvery } from 'redux-saga/effects';
import { setAuthUser, setIsAuth } from './index';
import { push } from 'connected-react-router';
import { FACEBOOK_SIGN_IN, GOOGLE_SIGN_IN, SIGN_IN_USER, SIGN_OUT_USER } from './constants';
import { UserInterface } from './types';
import { signOutUser, submitCredentials } from './api';
import { stageAction } from '../OnBoardingStage/actions';
import { setOnBoarded } from '../OnBoardingStage';


export function* authenticationFlow() {
  while (true) {
    const { start, googleSigned, facebookSigned } = yield race({
      start: take(SIGN_IN_USER),
      googleSigned: take(GOOGLE_SIGN_IN),
      facebookSigned: take(FACEBOOK_SIGN_IN)

    });

    if (start) {
      const user = yield startSession(start.payload);
      if (user) {
        yield put({ type: stageAction.FETCH_STAGE });
      } else {
        continue; //there was error during signin
      }
    }
     else if( googleSigned){
      const signedUser = yield startSessionPassport( googleSigned.payload);
      if (signedUser) {
        yield put({ type: stageAction.FETCH_STAGE });
      } else {
        continue; //there was error during signin
      } }

      else if( facebookSigned){
        const signedUser = yield startSessionPassport( facebookSigned.payload);
        if (signedUser) {
          yield put({ type: stageAction.FETCH_STAGE });
        } else {
          continue; //there was error during signin
        } }
  

    yield take(SIGN_OUT_USER);
  
  }
}

function* startSession(credentials) {
  const user: UserInterface = yield submitCredentials(credentials);

  if (user) {
    yield all([put(setIsAuth(true)), put(setAuthUser(user))]);
  }

  return user;
}


function* startSessionPassport(data) {

  if (data) {
    yield all([put(setIsAuth(true)), put(setAuthUser(data))]);
  }

  return data;
}


export function* logoutFlow() {
  yield takeEvery(SIGN_OUT_USER, logout);
}

function* logout() {
  yield signOutUser();
  yield all([put(setIsAuth(false)), put(setAuthUser(null)), put(setOnBoarded({ onBoarded: false }))]);
  return window.location.href = process.env.REACT_APP_MARKETING_APP;
}
