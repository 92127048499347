import axios from "axios";
import { authAction } from "../App/features/Auth/actions";
import { store } from "../App/App";

export const loadState = async () => {
  try {
    const serializedState = localStorage.getItem('state');
    
    const token = JSON.parse(serializedState!).auth.authUser.emailToken
    if(token){
     const {data:res} = await axios(`${process.env.REACT_APP_SSO_API}/users/getUserByEmailToken/${token}`)
     
     if(!res.isLoggedIn){
      store.dispatch({ type: authAction.SIGN_OUT_USER })
       
      return undefined
     }
     
    }

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
