import { red } from '@material-ui/core/colors';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

// A custom theme for Career Network app
const themeRTL = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontFamily: 'Roboto-Medium',
        fontSize: '0.88rem',
        fontWeight: 500,
        gap: 'inherit',
      },
      contained: {
        padding: '0.5rem 1.5rem',
        boxShadow: 'none',
      },
      containedPrimary: {
        '& > .MuiButton-label': {
          color: '#ffffff',
        },
      },
      containedSecondary: {
        '& > .MuiButton-label': {
          color: '#ffffff',
        },
      },
    },
    MuiInput: {
      root: {
        padding: '0.25rem 0.75rem',
        height: '2rem',
        border: '1px solid #e8e7e7',
        borderRadius: '3px',
        fontFamily: 'Roboto-Medium',
        fontSize: '0.8rem',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.03)',
      },
      underline: {
        '&:after': {
          borderBottom: 'none',
        },
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none',

          '@media (hover: none)': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        '& .MuiDialogActions-root button': {
          backgroundColor: '#01ada9',
          color: '#ffffff',
        },
        '& .MuiDialogActions-spacing > :not(:first-child)': {
          marginLeft: 0,
        },
        '& .MuiDialogActions-root': {
          gap: '8px',
        },
        '& .MuiPickersCalendarHeader-switchHeader': {
          flexDirection: 'row-reverse',
        },
        '& .MuiTypography-alignLeft': {
          textAlign: 'right',
        },
        '& .MuiPickersDatePickerRoot-dateLandscape': {
          marginRight: 0,
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 3%)',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
      },
    },
    MuiSelect: {
      icon: {
        color: '#b7babd',
      },
    },
  },
  palette: {
    primary: {
      main: '#01ada9',
    },
    secondary: {
      main: '#485c6e',
    },
    text: {
      primary: '#1b2024',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#094469',
      paper: '#ffffff',
    },
    facebook: {
      main: '#3b5998',
      dark: '#314A7E',
      light: '#4568B2',
    },
    linkedin: {
      main: '#2876b4',
      dark: '#226397',
      light: '#2F89D1',
    },
  },
  button: {
    submit: '#01ada9',
    facebook: '#3b5998',
    linkedin: '#2876b4',
  },
  text: {
    secondary: '#485c6e',
  },
  input: {
    borderColor: '#e8e7e7',
    placeholderColor: '#6c767e',
    backgroundColor: '#f7f7f7',
  },
});

export default themeRTL;
