export const SEARCH_JOB_AND_COMPANY_NAMES = 'feature/companiesAndJobs/SEARCH_JOB_AND_COMPANY_NAMES' as string;
export const SEARCH_JOB_CATEGORIES = 'feature/companiesAndJobs/SEARCH_JOB_CATEGORIES' as string;
export const SEARCH_JOBS = 'feature/companiesAndJobs/SEARCH_JOBS' as string;
export const SAVE_CURRENT_SEARCH = 'feature/companiesAndJobs/SAVE_CURRENT_SEARCH' as string;
export const FETCH_JOB = 'feature/companiesAndJobs/FETCH_JOB' as string;
export const FETCH_COMPANY = 'feature/companiesAndJobs/FETCH_COMPANY' as string;
export const FETCH_COMPANY_JOBS = 'feature/companiesAndJobs/FETCH_COMPANY_JOBS' as string;
export const FETCH_USER_JOBS = 'feature/companiesAndJobs/FETCH_USER_JOBS' as string;
export const APPLY_TO_JOB = 'features/companiesAndJobs/APPLY_TO_JOB' as string;
export const DECLINE_JOB = 'features/companiesAndJobs/DECLINE_JOB' as string;
export const SET_LOADING = 'features/companiesAndJobs/SET_LOADING' as string;