import { InputBase } from '@material-ui/core';
import { ChevronLeft, LiveHelp } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import clsx from 'clsx';
import { match } from 'node-match-path';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectIsAuth, selectPathName } from '../../App/features/Auth';
import { selectDirection } from '../../App/features/direction/directionSlice';
import { useAppSelector } from '../../App/hooks';
import LanguageSelector from '../../components/MainNavbar/NavbarFooter/LanguageSelector';
import { getRoutes, gradient, userProfileMenu } from './constants';
import styles from './Navigation.module.scss';

export async function changeLang(language: string) {
  try {
    await fetch(`${process.env.REACT_APP_SSO_API}/users/updateUserLanguage`, {
      method: 'PUT',
      headers: {
        Authorization: JSON.parse(localStorage.getItem('state') || '{}').auth.authUser.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language: language,
      }),
    });
  } catch (err) {
    // todo: handle error
    console.log(err);
  }
}

export async function fetchUserPreferences() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SSO_API}/users/getPrefrences`, {
      method: 'GET',
      headers: {
        Authorization: JSON.parse(localStorage.getItem('state') || '{}').auth.authUser.token,
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  } catch (err) {
    // todo: handle error
    console.log(err);
  }
}

export function updateUserStatePreferences(preferences) {
  const state = JSON.parse(localStorage.getItem('state') || '{}');
  state.auth.authUser.preferences = preferences;
  localStorage.setItem('state', JSON.stringify(state));
}

interface language {
  value: string;
  name: string;
}

const Navigation: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [languages, setLanguages] = useState<language[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<language | undefined>({ value: 'en', name: 'English' });
  const [showLangSelector, setShowLangSelector] = useState(false);

  const localStorageLanguage = localStorage.getItem('language');
  const isAuth = useAppSelector(selectIsAuth);
  const url = useAppSelector(selectPathName);
  const { search, pathname } = useLocation();
  const { t } = useTranslation([
    'mainNavbar',
    'navigation',
    'helpCentreCareerTranslate',
    'usingNoviopusCareerTranslate',
    'usingNoviopusBusinessTranslate',
    'careerFaqTranslate',
    'helpCentreCareerTranslate',
    'businessFaqTranslate',
  ]);

  useEffect(() => {
    setActiveLink(pathname + search);
  }, [pathname, search]);

  async function fetchLanguagesList() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API}/career/languages/loadedLanguages`);
      const list = await response.json();
      setLanguages(list);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchLanguagesList();
  }, []);

  useEffect(() => {
    setCurrentLanguage(languages.find(l => l.value === (localStorageLanguage || localStorage.getItem('i18nextLng'))));
  }, [languages, localStorageLanguage]);

  const routes = getRoutes(isAuth);
  const currentRoute = routes.find(r => match(r.path, url).matches);
  const menu: any = currentRoute?.menu;

  const isHelpCenter = url === '/help/career' || url === '/help/business';
  const direction = useAppSelector(selectDirection);

  const userState = useSelector((state: any) => state.auth.authUser);
  const fullName = userState?.firstname && userState?.lastname ?  userState?.firstname + ' ' + userState?.lastname : '' ;

  const scrollTo = (): void => {
    const params = new URLSearchParams(search);
    const par = params.get('id');
    const el = document.getElementById(`${par}`);
    if (par === 'help') {
      el?.scrollIntoView(false);
    } else {
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  scrollTo();

  return (
    <div className={styles.navigation}>
      <div className={styles.userMenu}>
        {/* User profile section */}
        {userProfileMenu?.links.map((link, i) => (
          <Link
            key={`${i}${link.title}`}
            to={link.href}
            className={link.href === activeLink ? clsx(styles.menuItem, styles.menuItemActive) : styles.menuItem}>
            {link.href === activeLink ? <div style={{ display: 'flex' }}>{link.activeIcon}</div> : link.icon}
            {t(`${fullName}`)}
            {/* {!link.title ? t(`${fullName}`) : t(`${link.title}`)} */}
          </Link>
        ))}
      </div>

      {menu?.backRoute && (
        <Link className={styles.back} to={menu.backRoute}>
          <ChevronLeft style={direction === 'rtl' ? { transform: 'scaleX(-1)' } : undefined} />
          {t(`${menu.backTitle}`)}
        </Link>
      )}

      <div className={styles.links}>
        {/* Menu link changing in this section */}
        {menu?.links.map((link, i) => (
          <Link
            key={`${i}${link.title}`}
            className={link.href === activeLink ? clsx(styles.menuItem, styles.menuItemActive) : styles.menuItem}
            to={link.href}>
            {link.href === activeLink ? <div>{link.activeIcon}</div> : link.icon}
            {t(`${link.title}`)}
          </Link>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.footerMainItems}>
          <Link
            className={isHelpCenter ? clsx(styles.footerItem, styles.footerItemActive) : styles.footerItem}
            to="/help/career?id=help">
            {isHelpCenter ? (
              <div>
                {gradient}
                <LiveHelp style={{ fill: 'url(#linearColors)' }} />
              </div>
            ) : (
              <LiveHelp />
            )}
            {t('navigation:helpSupport')}
          </Link>
          {showLangSelector && <LanguageSelector setShowMobileLangSelector={setShowLangSelector} isDesktop />}
          <div className={styles.footerItem}>
            <LanguageIcon />
            <div>
              <InputBase
                value={currentLanguage?.name}
                placeholder={'Language'}
                onClick={() => setShowLangSelector(true)}
                inputProps={{ readOnly: true }}
              />
            </div>
          </div>
        </div>
        <div className={styles.footerBottomItems}>
          <div className={clsx(styles.footerItem, styles.terms)}>
            <Link to="/terms">{t('navigation:terms')}</Link>&middot;
            <Link to="/privacy">{t('navigation:privacy')}</Link>
          </div>
          <div className={clsx(styles.footerItem, styles.terms)}>
            <span>
              {t('noviopus')}&nbsp;&copy;{currentYear}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
