import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { getErrorMessage, get, post } from '../../../utils/request';
import { showErrorToast } from 'components/Toast/actions';
import {
  setJobNames, setCompanies, setJobs, setCurrentJob, setCurrentCompany, setCompanyJobs,
  setJobCategories, setUserJobs,
} from '.';

export function* searchJobAndCompanyNamesApi(name) {
  try {
    const jobNamesData = yield call(get, { url: `/jobNames?name=${name}`, businessUtility: true });
    const companiesData = yield call(get, { url: `/companies?name=${name}`, businessUtility: true });

    yield put(setJobNames(jobNamesData));
    yield put(setCompanies(companiesData));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* searchJobCategoriesApi(name) {
  try {
    const jobCategories = yield call(get, { url: `/career/job-categories?name=${name}` });

    yield put(setJobCategories(jobCategories));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* searchJobsApi({ type, name, city, state, country , placeId}) {
  try {
    let url;
    if (type === 'job') {
      url = `/jobs?`;
      if (name) {
        url += `name=${name}&`;
      }
    } else if (type === 'company') {
      url = `/companyJobsByName?`;
      if (name) {
        url += `companyName=${name}&`;
      }
    }
    if (placeId){
      url += `placeId=${placeId}&`;
    }
    if (city) {
      url += `city=${city}&`;
    }
    if (state) {
      url += `state=${state}&`;
    }
    if (country) {
      url += `country=${country}`;
    }
    const jobs = yield call(get, { url, businessUtility: true });
    yield put(setJobs(jobs));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchJobApi(id) {
  try {
    const jobData = yield call(get, { url: `/job/${id}`, businessUtility: true });
    yield put(setCurrentJob(jobData));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchCompanyApi(id) {
  try {
    const companyData = yield call(get, { url: `/company/${id}`, businessUtility: true });
    yield put(setCurrentCompany(companyData));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchCompanyJobsApi(id) {
  try {
    const jobsData = yield call(get, { url: `/company/${id}/jobs`, businessUtility: true });
    yield put(setCompanyJobs(jobsData));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchUserJobsApi() {
  try {
    const jobsData = yield call(get, { url: `/recruiter/jobs` });
    yield put(setUserJobs(jobsData));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(showErrorToast(errorMessage));
  }
}

export function* applyToJobApi(payload: PayloadAction) {
  try {
    console.log('applyToJobAPI payload', payload)
    yield call(post, { url: '/recruiter/candidate', data: payload });
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* declineJobApi(payload: PayloadAction) {
  try {
    yield call(post, { url: '/recruiter/decline-job', data: payload });
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}