import { all } from 'redux-saga/effects';
import { authenticationFlow, logoutFlow } from './features/Auth';
import { stageSaga } from './features/OnBoardingStage/sagas';
import { watchUserCommentsSaga } from './features/Comments/sagas';
import { watchToastQueue } from 'components/Toast/sagas';

function* appSaga() {
  yield all([
    logoutFlow(), 
    authenticationFlow(), 
    stageSaga(),
    watchToastQueue(),
    watchUserCommentsSaga ()
    ]);
}

export default appSaga;
