import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    maxWidth: '280px',
    height: '36px',
    fontWeight: 400,
    '& .MuiInput-root': {
      borderRadius: '7px',
      border: 'none',
      fontFamily: 'Roboto-Light, Arial, sans-serif',
      fontSize: '16px',
      backgroundColor: 'rgba(224, 239, 243, 1)',
      padding: 0,
      color: '#5C6268',
      borderBottom: 'none',

      '&:hover': {
        borderRadius: '7px 7px 0px 0px',
        borderBottom: '2px solid rgb(0, 121, 155)',
      },
      '&:focus-within': {
        backgroundColor: 'white',
        borderRadius: '7px 7px 0px 0px',
        borderBottom: '2px solid rgb(0, 121, 155)',
        outline: '0.5px solid rgba(72,92,110,0.25)',
      },
    },
    '& .MuiInput-input': {
      padding: '7px 14px',
      fontSize: '16px',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      '& .MuiInput-input': {
        padding: '5px 10px',
        fontSize: 14,
      },
    },
  },
  uneditableInput: {
    '& .MuiInput-root': {
      backgroundColor: 'rgba(217, 217, 217, 1)',
      fontWeight: 400,
      '&:hover': {
        borderRadius: '7px',
        borderBottom: 'none',
      },
      '&:focus-within': {
        backgroundColor: 'white',
        borderRadius: '7px 7px 0px 0px',
        borderBottom: '2px solid rgb(0, 121, 155)',
      },
    },
  },
  fieldLabel: {
    fontFamily: 'Roboto-Medium, Arial, sans-serif',
    marginBottom: 7,
    marginLeft: 4,
    color: '#485C6E',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 5,
      marginLeft: 2,
    },
  },
  clearIndicatorDirty: {},
  clearIndicator: {
    visibility: 'hidden',
  },
}));
