import { call, put } from 'redux-saga/effects';
import { get, getErrorMessage } from 'utils/request';
import { onBoardingStageInterface } from './types';
import { showErrorToast } from 'components/Toast/actions';

export function* fetchStage() {
  let userStage: onBoardingStageInterface | null = null;
  try {
    userStage = yield call(get, { url: '/career/onBoarding/userStage' });
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
  return userStage;
}
