import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { reducer as authReducer } from './features/Auth';
import { AuthInterface } from './features/Auth/types';
import { ToastInterface, toastReducer } from '../components/Toast/toastSlice';
import { onBoardingStageInterface } from './features/OnBoardingStage/types';
import { reducer as stageReducer } from './features/OnBoardingStage';
import { CommentsState } from './features/Comments/types';
import { reducer as commentsReducer } from './features/Comments';
import directionReducer, { DirectionState } from './features/direction/directionSlice';
import languagesListReducer, { LanguagesListState } from './features/languagesList/languagesListSlice';

export interface StaticState {
  router: (state: RouterState, action: AnyAction) => RouterState<any>;
  stage: Reducer<onBoardingStageInterface, AnyAction>;
  auth: Reducer<AuthInterface, AnyAction>;
  toast: Reducer<ToastInterface, AnyAction>;
  comments: Reducer<CommentsState,  AnyAction>;
  direction: (state: DirectionState, action: AnyAction) => DirectionState;
  languagesList: (state: LanguagesListState, action: AnyAction) => LanguagesListState;
}

const createReducer = (history: History, asyncReducers: any = null) => {
  const staticReducers = {
    comments: commentsReducer,
    auth: authReducer,
    router: connectRouter(history),
    toast: toastReducer,
    stage: stageReducer,
    direction: directionReducer,
    languagesList: languagesListReducer,
  } as StaticState;

  const reducers = combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
  return reducers;
};

export { createReducer };
