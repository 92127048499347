import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorToast } from 'components/Toast/actions';
import { call, put } from 'redux-saga/effects';
import {get, destroy, getErrorMessage, post, put as putRequest, } from 'utils/request';
import { setComments } from '.';

export function* addCommentsApi(payload: PayloadAction) {
  try {
    yield call(post, { url: `/comments/add`, data: payload, comments: true });
    console.log(" addCommentsApi")
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    console.log("error addCommentsApi")
    yield put(showErrorToast(errorMessage));
  }
}

export function* getCommentsbyAlbumApi(albumId: string) {
  try {
    const data = yield call(get,{url:`/comments/getCommentsByAlbumId/${albumId}`,comments: true});
    console.log("🚀 ~ file: api.ts:22 ~ function*getCommentsbyAlbumApi ~ data:get", data)
    yield put(setComments(data));
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    
    yield put(showErrorToast(errorMessage));
  }
}


// export function* getCommentsbyUser(userId: string) {
//   try {
//     const data = yield call(get,{url:`/comments/getCommentsByUserId?search=${userId}`,comments: true});
//     // yield put(setComments(data));
//     console.log("🚀 ~ file: api.ts:35 ~ function*getCommentsbyUser ~ data:", data)
//   } catch (e) {
//     const errorMessage = getErrorMessage(e);

//     yield put(showErrorToast(errorMessage));
//   }
// }

export function* deleteCommentsApi(id: string) {
  try {
    yield call(destroy, { url: `/comments/delete?search=${id}`, comments: true });
    
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* editCommentsApi(payload: PayloadAction) {
  try {
    yield call(putRequest, { url: `/comments/update`,data:payload, comments: true });
    console.log("🚀 ~ file: api.ts:59 ~ function*editCommentsApi ~payload" ,payload)

  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* hideCommentsApi(id: any) {
  try {
    yield call(get, { url: `/comments/hide?search=${id}`, comments: true });

  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* reportCommentsApi(id: any) {
  try {
    yield call(get, { url: `/comments/report?search=${id}`, comments: true });

  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}