import React, { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import localStyles from './LanguageSelector.module.scss';
import { useStyles } from '../styles';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import i18n from 'i18n';
import { changeLang, fetchUserPreferences, updateUserStatePreferences } from 'components/Navigation';
import { useAppDispatch, useAppSelector } from 'App/hooks';
import { selectLanguagesList } from 'App/features/languagesList/languagesListSlice';
import { setDirection } from 'App/features/direction/directionSlice';
import { CircleFlag } from 'react-circle-flags';
import { mapLanguageToCountryCode } from './countryCodeMapper';
import { useTranslation } from 'react-i18next';
import { selectDirection } from 'App/features/direction/directionSlice';

interface LanguageSelectorProps {
  setShowMobileLangSelector: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  isDesktop?: boolean;
}

interface language {
  value: string;
  name: string;
  direction: string;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ setShowMobileLangSelector, onClose, isDesktop }) => {
  const languagesList: language[] = useAppSelector(selectLanguagesList);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<language[]>(languagesList);
  const { t } = useTranslation(['languageRegionTranslate', 'languagesTranslate']);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const direction = useAppSelector(selectDirection);

  useEffect(() => {
    setSearchResults(languagesList);
  }, [languagesList]);

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchResults(languagesList);
  };

  const handleSearch = e => {
    const { value } = e.target;
    setSearchValue(value);
    if (!value) {
      setSearchResults(languagesList);
    } else {
      setSearchResults(languagesList.filter(language => language.name.toLowerCase().startsWith(value.toLowerCase())));
    }
  };

  const handleLangChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    const user = JSON.parse(localStorage.getItem('state') || '{}').auth.authUser !== null;

    if (user) {
      changeLang(language).then(() => {
        fetchUserPreferences().then(pref => {
          updateUserStatePreferences(pref);

          // updating direction
          const direction = pref.language.direction.toLowerCase();
          dispatch(setDirection(direction));
          localStorage.setItem('direction', direction);
          document.getElementsByTagName('html')[0].setAttribute('dir', direction);
        });
      });
    } else {
      const currentDirection = languagesList.find(lang => lang.value === language)?.direction.toLowerCase();
      if (currentDirection) {
        localStorage.setItem('direction', currentDirection);
        currentDirection && dispatch(setDirection(currentDirection));
        document.getElementsByTagName('html')[0].setAttribute('dir', currentDirection);
      }
    }
  };

  const handleSelectorClose = () => {
    setSearchValue('');
    setSearchResults(languagesList);
    onClose && onClose();
    setShowMobileLangSelector(false);
  };

  return (
    <div className={localStyles.container}>
      <div
        className={localStyles.LanguagesPopup}
        style={
          direction === 'ltr' ? { marginRight: '370px', marginLeft: '0' } : { marginLeft: '400px', marginRight: '0' }
        }>
        <div className={localStyles.title}>
          <div className={localStyles.Typography}>{t('noviopusLanguage')}</div>
          {isDesktop && (
            <IconButton
              onClick={() => setShowMobileLangSelector(false)}
              className={localStyles.IconButton}
              style={direction === 'rtl' ? { right: 'unset', left: '16px' } : undefined}>
              <CloseIcon className={classes.inputClearBtn} />
            </IconButton>
          )}
        </div>
        <div className={localStyles.LanguagesContainer}>
          <TextField
            placeholder={t('languagesTranslate:languageSection.placeholders.searchLanguage')}
            className={localStyles.searchField}
            type="text"
            onKeyDown={e => e.stopPropagation()}
            value={searchValue}
            onChange={handleSearch}
            InputProps={{
              classes: {
                root: localStyles.searchField,
                focused: localStyles.focusedTextField,
                input: `${classes.input} ${classes.languageInput}`,
              },
              endAdornment: (
                <InputAdornment position="start" className={localStyles.searchFieldLabel}>
                  {!searchValue ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon className={classes.inputClearBtn} onClick={handleClearSearch} />
                  )}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
          <div className={localStyles.languagesList}>
            {searchResults.map((item, index) => (
              <div
                key={index}
                className={localStyles.option}
                onClick={() => {
                  handleLangChange(item.value);
                  handleSelectorClose();
                }}>
                <CircleFlag className={localStyles.CircleFlag} countryCode={mapLanguageToCountryCode(item)} />
                <div className={localStyles.LanguageName}>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
