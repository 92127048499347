import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useAppDispatch } from '../../App/hooks';
import { hideActiveToast } from './toastSlice';
import { IToast } from './types';

const Toast = (props: IToast): JSX.Element => {
  const { message = 'This is a success message!', type } = props;
  const dispatch = useAppDispatch();

  return (
    <Snackbar open onClose={() => dispatch(hideActiveToast())}>
      <MuiAlert elevation={6} variant="filled" onClose={() => dispatch(hideActiveToast())} severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Toast;
