import {
  ADD_PROFILE_EDUCATION_ITEM,
  DELETE_PROFILE_EDUCATION_ITEM,
  EDIT_PROFILE_EDUCATION_ITEM,
  FETCH_PROFILE_EDUCATION,
  SET_PROFILE_EDUCATION,
  FETCH_SKILL_NAMES,
  FETCH_DEGREE_NAMES,
  FETCH_SCHOOL_NAMES,
  FETCH_FIELD_NAMES,
} from './constants';
import {
  clearForm,
  increaseChangeCounter,
  removeEducationItemByIndex,
  setEducationItem,
  setEducationItemByIndex,
} from '.';
import { push } from 'connected-react-router';
import { selectPathName } from 'App/features/Auth';
import { showSuccessToast } from 'components/Toast/actions';
import {
  createEducationApi, fetchEducationApi, fetchSkillsSourceApi, fetchDegreesSourceApi,
  fetchSchoolsSourceApi, fetchFieldsSourceApi,
} from './api';
//import { selectChangeCounter } from '.';
import { put, takeEvery, select } from 'redux-saga/effects';

function* createEducation({ payload }: { payload: any; type: typeof SET_PROFILE_EDUCATION }) {
  yield createEducationApi(payload);

  // const changeCounter = yield select(selectChangeCounter);
  // if (changeCounter) {
  //   yield createEducationApi(payload);
  // }
  const pathname = yield select(selectPathName);
  if (pathname !== '/account/education/edit') {
    yield put(push('/account/experience'));
  } else {
    yield put(showSuccessToast('Your changes were saved successfully.'));
  }
}

function* loadEducation() {
  yield fetchEducationApi();
}

function* setEducationItemSaga(payload) {
  yield put(setEducationItem(payload));
  yield put(clearForm());
  yield put(increaseChangeCounter());
}

function* editEducationItemSaga(payload) {
  yield put(setEducationItemByIndex(payload));
  yield put(increaseChangeCounter());
}

function* deleteEducationItemSaga(payload) {
  yield put(removeEducationItemByIndex(payload));
  yield put(increaseChangeCounter());
}

function* loadSkillsSource({ payload }: { payload: any; type: typeof FETCH_SKILL_NAMES }) {
  yield fetchSkillsSourceApi(payload);
}

function* loadDegreesSource({ payload }: { payload: any; type: typeof FETCH_DEGREE_NAMES }) {
  yield fetchDegreesSourceApi(payload);
}

function* loadSchoolsSource({ payload }: { payload: any; type: typeof FETCH_SCHOOL_NAMES }) {
  yield fetchSchoolsSourceApi(payload);
}

function* loadFieldsSource({ payload }: { payload: any; type: typeof FETCH_FIELD_NAMES }) {
  yield fetchFieldsSourceApi(payload);
}

export function* watchEducationSaga() {
  yield takeEvery(FETCH_PROFILE_EDUCATION, loadEducation);
  yield takeEvery(SET_PROFILE_EDUCATION, createEducation);
  yield takeEvery(ADD_PROFILE_EDUCATION_ITEM, setEducationItemSaga);
  yield takeEvery(EDIT_PROFILE_EDUCATION_ITEM, editEducationItemSaga);
  yield takeEvery(DELETE_PROFILE_EDUCATION_ITEM, deleteEducationItemSaga);
  yield takeEvery(FETCH_SKILL_NAMES, loadSkillsSource);
  yield takeEvery(FETCH_DEGREE_NAMES, loadDegreesSource);
  yield takeEvery(FETCH_SCHOOL_NAMES, loadSchoolsSource);
  yield takeEvery(FETCH_FIELD_NAMES, loadFieldsSource);
}
