// import { takeEvery, put } from 'redux-saga/effects';
import { takeEvery} from 'redux-saga/effects';
import { 
  // SAVE_USER_COMMENTS, 
  HIDE_USER_COMMENTS,
  SAVE_ALBUM_COMMENTS, 
  UPDATE_USER_COMMENTS, 
  DELETE_USER_COMMENTS,
  FETCH_ALBUM_COMMENTS,
  REPORT_USER_COMMENTS } from './constants';
// import { showSuccessToast } from 'components/Toast/actions';
import {
  // getCommentsbyUser,
  getCommentsbyAlbumApi,
  editCommentsApi,
  deleteCommentsApi,
  addCommentsApi,
  hideCommentsApi,
  reportCommentsApi
} from './api';

// function* saveUserComments({ payload }: { payload: any; type: typeof SAVE_USER_COMMENTS }) {
//   yield addCommentsApi(payload)
//   yield getCommentsbyUser(payload.idUser)
//   // yield put(showSuccessToast('Your comment was save successfully.'));
// }


function* saveAlbumComments({ payload }: { payload: any; type: typeof SAVE_ALBUM_COMMENTS }) {
  const {albumId} = payload
  yield addCommentsApi(payload)
  yield getCommentsbyAlbumApi(albumId)
  // yield put(showSuccessToast('Comment was save successfully.'));

}

function* deleteUserComments({ payload }: { payload: any; type: typeof DELETE_USER_COMMENTS }) {
  const {id, albumId} = payload
  yield deleteCommentsApi(id)
  yield getCommentsbyAlbumApi(albumId)
  // yield put(showSuccessToast('Comment was delete successfully.'));
}

function* editUserComments({ payload }: { payload: any; type: typeof UPDATE_USER_COMMENTS }) {
  const { albumId} = payload
  yield editCommentsApi(payload)
  yield getCommentsbyAlbumApi(albumId)
  // yield put(showSuccessToast('Comment was update successfully.'));
}

function* hideUserComments({ payload }: { payload: any; type: typeof HIDE_USER_COMMENTS }) {
  const {id, albumId} = payload
  yield hideCommentsApi(id)
  yield getCommentsbyAlbumApi(albumId)
  // yield put(showSuccessToast('Comment was hide successfully.'));
}
function* reportUserComments({ payload }: { payload: any; type: typeof REPORT_USER_COMMENTS }) {
  const {id, albumId} = payload
  yield reportCommentsApi(id)
  yield getCommentsbyAlbumApi(albumId)
  // yield put(showSuccessToast('Comment was reported'));
}

function* fetchAlbumComments({ payload }: { payload: any; type: typeof FETCH_ALBUM_COMMENTS }) {
  // const {albumId} = payload
  yield getCommentsbyAlbumApi(payload)
}


export function* watchUserCommentsSaga() {
  // yield takeEvery(SAVE_USER_COMMENTS, saveUserComments);
  yield takeEvery(SAVE_ALBUM_COMMENTS, saveAlbumComments);
  yield takeEvery(DELETE_USER_COMMENTS, deleteUserComments);
  yield takeEvery(UPDATE_USER_COMMENTS, editUserComments);
  yield takeEvery(FETCH_ALBUM_COMMENTS, fetchAlbumComments);
  yield takeEvery(HIDE_USER_COMMENTS, hideUserComments);
  yield takeEvery(REPORT_USER_COMMENTS, reportUserComments);

}
