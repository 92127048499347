import { EducationItemInterface } from './types';

export const FETCH_PROFILE_EDUCATION = 'features/Education/FETCH_PROFILE_EDUCATION' as string;
export const SET_PROFILE_EDUCATION = 'features/Education/SET_PROFILE_EDUCATION' as string;
export const ADD_PROFILE_EDUCATION_ITEM = 'features/Education/ADD_PROFILE_EDUCATION_ITEM' as string;
export const EDIT_PROFILE_EDUCATION_ITEM = 'features/Education/EDIT_PROFILE_EDUCATION_ITEM' as string;
export const DELETE_PROFILE_EDUCATION_ITEM = 'features/Education/DELETE_PROFILE_EDUCATION_ITEM' as string;
export const FETCH_SKILL_NAMES = 'features/Profile/FETCH_SKILL_NAMES' as string;
export const SET_SKILL_NAMES = 'features/Profile/SET_SKILL_NAMES' as string;
export const FETCH_DEGREE_NAMES = 'features/Profile/FETCH_DEGREE_NAMES' as string;
export const SET_DEGREE_NAMES = 'features/Profile/SET_DEGREE_NAMES' as string;
export const FETCH_SCHOOL_NAMES = 'features/Profile/FETCH_SCHOOL_NAMES' as string;
export const SET_SCHOOL_NAMES = 'features/Profile/SET_SCHOOL_NAMES' as string;
export const FETCH_FIELD_NAMES = 'features/Profile/FETCH_FIELD_NAMES' as string;
export const SET_FIELD_NAMES = 'features/Profile/SET_FIELD_NAMES' as string;

export const initialEducation: EducationItemInterface = {
  skills: [],
  degree: {
    name: '',
  },
  schoolName: {
    name: '',
  },
  fieldOfStudy: {
    name: '',
  },
  fromYear: new Date().toISOString(),
  toYear: new Date().toISOString(),
  CostAndFees: 0,
  EducationLevel: 0,
  InternshipOpportunities: 0,
  CampusLife: 0,
  CampusFacilities: 0,
  _id: '',
};
