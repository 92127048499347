import { RootState } from 'App/App';
import { educationSlice } from './educationSlice';
import { watchEducationSaga } from './sagas';

export const {
  updateFormValue,
  clearForm,
  setEducation,
  setEducationItem,
  setEducationItemByIndex,
  removeEducationItemByIndex,
  increaseChangeCounter,
  clearChangeCounter,
  setSkillNames,
  setDegreeNames,
  setSchoolNames,
  setFieldNames,
} = educationSlice.actions;

export const reducer = educationSlice.reducer;

export { watchEducationSaga as saga };

export const selectEducation = (state: RootState) => state.education.education;
export const selectChangeCounter = (state: RootState) => state.education.changeCounter;
export const selectForm = (state: RootState) => state.education.form;
export const selectSkillNames = (state: RootState) => state.education.skillNames;
export const selectDegreeNames = (state: RootState) => state.education.degreeNames;
export const selectSchoolNames = (state: RootState) => state.education.schoolNames;
export const selectFieldNames = (state: RootState) => state.education.fieldNames;