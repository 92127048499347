import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../App/hooks';
import { selectIsAuth } from '../../App/features/Auth';

interface PrivateRouteProps extends RouteProps {}

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const isAuth = useAppSelector(selectIsAuth);
 
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuth) {
          return children;
        } else {
          window.location.href = process.env.REACT_APP_MARKETING_APP;
          return null;
        }
      }}
    />
  );
}

export { PrivateRoute as default };
