import { call, put } from 'redux-saga/effects';
import { getErrorMessage, get, post } from '../../../utils/request';
import { UserInterface } from './types';
import { showErrorToast } from 'components/Toast/actions';

export function* submitCredentials(credentials) {
  let user: UserInterface | null = null;
  try {
    user = yield call(post, { url: '/users/signin', data: credentials, sso: true });
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = getErrorMessage(error);
    }
    yield put(showErrorToast(errorMessage));
  }
  return user;
}
export function* signOutUser() {
  return yield call(get, { url: '/users/signout', sso: true });
}
