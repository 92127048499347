import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    dialogActions: {
      '& button': {
        backgroundColor: '#00799B', // Replace with your desired background color
        color: '#ffffff', // Optional: Set the text color
      },
    },
    input: {
      width: '100%',
      height: 33,

      '& .MuiInput-root': {
        fontFamily: 'Roboto-Light, Arial, sans-serif',
        backgroundColor: 'rgba(224, 239, 243, 1)',
        borderRadius: '7px',
        border: 'none',
        fontSize: '16px',
        padding: 0,
        color: '#5C6268',
        borderBottom: 'none',

        '&:hover': {
          borderRadius: '7px 7px 0px 0px',
          borderBottom: '2px solid rgb(0, 121, 155)',
        },
        '&:focus-within': {
          backgroundColor: 'white',
          borderRadius: '7px 7px 0px 0px',
          borderBottom: '2px solid rgb(0, 121, 155)',
          outline: '0.5px solid rgba(72,92,110,0.25)',
        },
      },
      '& .MuiInput-input': {
        padding: '7px 14px',
        fontSize: '16px',
        // textAlign: 'center',
      },
      '& .MuiIconButton-root': {
        paddingRight: 9,
        // padding: 0,
        margin: 0,
      },
    },
    inputRtl: {
      width: '100%',
      height: 33,

      '& .MuiInput-root': {
        fontFamily: 'Roboto-Light, Arial, sans-serif',
        fontSize: '0.9rem',
        backgroundColor: theme.input.backgroundColor,
        padding: '0 0 0 56px !important',

        '& .MuiInput-input': {
          padding: '7px 14px',
        },
        '& .MuiIconButton-root': {
          padding: 9,
        },
      },
    },
    inputError: {
      '& .MuiInput-root': {
        borderColor: 'red',
      },
    },
    autocomplete: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'unset',
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd':
        {
          padding: '7px 14px',
        },
    },
    autocompleteRtl: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'unset',
        left: 0,
        right: 'unset',
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd':
        {
          padding: '7px 14px',
        },
    },
    autocompleteNoIcon: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'unset',
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd':
        {
          padding: '7px 14px',
        },
      '&:hover': {
        '& .MuiAutocomplete-clearIndicator': {
          visibility: 'visible',
        },
        '& .MuiAutocomplete-popupIndicator': {
          display: 'none',
        },
      },
    },
    autocompleteNoIconRtl: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'unset',
        left: 0,
        right: 'unset',
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd':
        {
          padding: '7px 14px',
        },
      '&:hover': {
        '& .MuiAutocomplete-clearIndicator': {
          visibility: 'visible',
        },
        '& .MuiAutocomplete-popupIndicator': {
          display: 'none',
        },
      },
    },
  };
});
