import { Badge, Theme, makeStyles, withStyles } from '@material-ui/core';

interface StyleProps {
  isOpen: boolean;
  //popupIndicator: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  toolbar: {
    minHeight: 63,
    height: 63,
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #e8e7e7',
    '& .MuiIconButton-root': {
      padding: 4,
    },
  },
  toolbarSignup: {
    minHeight: 100,
    justifyContent: 'space-between',
    border: 'solid 1px #e8e7e7',
    '@media (max-width:959px)': {
      minHeight: 52,
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
  },
  userAvatar: {
    '@media (max-width:959px)': {
      width: 20,
      height: 20,
    },
  },
  autocompleteRtl: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '4px 9px 9px 65px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      left: '9px',
      right: 'unset !important',
    },
  },
  autocomplete: {
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      marginRight: '10px',
    },
    '& .MuiAutocomplete-input': {
      marginLeft: ' 10px',
    },
  },
  autocompleteSelected: {
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      color: '#00799B',
      marginRight: '10px',
    },
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      display: 'none',
    },
    '& .MuiAutocomplete-input': {
      marginLeft: ' 10px',
    },
  },
  autocompleteOptionsMobile: {
    backgroundColor: 'white',
    '& .MuiAutocomplete-paper': {
      width: '100vw',
      marginTop:'4px',
    },
    '& .MuiInputBase-root ': {
      height:'59px'
    },
    	
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: props =>
            props.isOpen
              ? "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')"
              : "path('M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z')",
        },
      },
    },
  },
}));

export const StyledBadge = withStyles({
  badge: {
    height: '16px',
    width: '16px',
    minWidth: '15px',
    fontSize: '10px',
    top: '20%',
    left: '20px',
  },
})(Badge);
