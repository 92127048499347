import { call, put } from 'redux-saga/effects';
import {
  clearChangeCounter, setEducation, setDegreeNames, setSchoolNames, setFieldNames,
  setSkillNames,
} from '.';
import { get, getErrorMessage, post } from 'utils/request';

import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorToast } from 'components/Toast/actions';

export function* createEducationApi(payload: PayloadAction) {
  try {
    yield call(post, { url: '/career/onBoarding/education', data: payload });
    yield put(clearChangeCounter());
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchEducationApi() {
  try {
    const data = yield call(get, { url: '/career/onBoarding/education' });

    yield put(setEducation(data));
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchDegreesSourceApi(search: string) {
  try {
    const names = yield call(get, { url: `/career/degree-names?search=${search}` });

    yield put(setDegreeNames(names.map(n => ({ neoId: n.id, name: n.name }))));
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchSchoolsSourceApi(search: string) {
  try {
    const names = yield call(get, { url: `/schoolNames?name=${search}`, businessUtility: true });

    yield put(setSchoolNames(names));
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchFieldsSourceApi(search: string) {
  try {
    const names = yield call(get, { url: `/career/field-names?search=${search}` });

    yield put(setFieldNames(names.map(n => ({ neoId: n.id, name: n.name }))));
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}

export function* fetchSkillsSourceApi(search: string) {
  try {
    const names = yield call(get, { url: `/career/skill-names?search=${search}` });

    yield put(setSkillNames(names.map(n => ({ neoId: n.id, name: n.name }))));
  } catch (e) {
    const errorMessage = getErrorMessage(e);

    yield put(showErrorToast(errorMessage));
  }
}