import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import { createReducer } from './reducers';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import appSaga from './sagas';
import { createSagaInjector } from '../utils/createSagaInjector';
import { loadState } from '../utils/localStorage';

const isProduction = process.env.NODE_ENV === 'production';

const sagaMiddleware = createSagaMiddleware();

const persistedState: any = await loadState();

export default function createStore(history: History<any>) {
  const middleware = [sagaMiddleware, routerMiddleware(history)];
  const reducer = createReducer(history);

  const store: any = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      !isProduction ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
    devTools: !isProduction,
    preloadedState: persistedState,
    enhancers: [applyMiddleware(...middleware)],
  });

  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(history, store.asyncReducers));
  };

  // Create an inject saga function
  // This function adds the async saga, and creates a new combined saga
  store.injectSaga = createSagaInjector(sagaMiddleware.run, appSaga);

  return store;
}
