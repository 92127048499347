import { StylesProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import { ConnectedRouter } from 'connected-react-router';
import DateFnsUtils from '@date-io/date-fns';
import GlobalStateProvider from '../App/context/GlobalStateContext'
import { I18nextProvider } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { RouterWrapper } from './router';
import createStore from './store';
import { history } from './history';
import i18n from '../i18n';
import { saveState } from '../utils/localStorage';
import throttle from 'lodash/throttle';
import { fetchUserPreferences } from '../components/Navigation';

const tagManagerArgs = {
  gtmId: 'GTM-M2MKXJT',
};

if (window.location.hostname === 'career.noviopus.com') {
  TagManager.initialize(tagManagerArgs);
}

export const store = createStore(history);

// Persist state to localStorage
store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
      stage: store.getState().stage,
    });
  }, 1000),
);

const App: React.FC = (): JSX.Element => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('state') || '{}').auth.authUser !== null;
    if (!localStorage.getItem('language')) {
      if (user) {
        fetchUserPreferences().then(data => {
          // setting language
          const userLanguage = data ? data.language.langName : 'en';
          i18n.changeLanguage(userLanguage);
        });
      } else {
        //setting language if user isn't connected
        const defaultLanguage = 'en';
        i18n.changeLanguage(defaultLanguage);
        localStorage.setItem('language', defaultLanguage);
        localStorage.setItem('direction', 'ltr');
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <I18nextProvider i18n={i18n}>
            <StylesProvider injectFirst>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GlobalStateProvider>
                  <RouterWrapper store={store} />
                </GlobalStateProvider>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </I18nextProvider>
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
