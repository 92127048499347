import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styles from './FAQ.module.scss';
import { useLocation } from 'react-router-dom';

const FAQ: React.FC = () => {
  const [markdownContent, setMarkdownContent] = useState<string>('');
  const localLanguage = localStorage.getItem('language');
  const { t } = useTranslation('careerFaqTranslate');
  const { search } = useLocation();

  const ids = [
    'new-account',
    'confirm-email',
    'confirm-email-issues',
    'sign-in',
    'reset-password',
    'job-search',
    'apply-decline-jobs',
    'personal-profile',
    'career-profile',
    'job-preferences',
    'workplace-preferences',
    'connections',
    'personal-interests',
    'change-password',
    'suspend-account',
    'delete-account',
  ];

  const scrollTo = (): void => {
    const params = new URLSearchParams(search);
    const par = params.get('id');
    const el = document.getElementById(`${par}`);
    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  scrollTo();

  function setIds(root: HTMLElement | null): void {
    if (root === null) return;
    const setId = (el, id) => (el.id = id);
    Array.from(root.querySelectorAll('h1')).forEach((el, i) => setId(el, ids[i]));
    scrollTo();
  }

  const fetchMarkdownContent = async (source, body) => {
    await fetch(source, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
      });

    setIds(document.querySelector(`.${styles.content}`));
  };

  useEffect(() => {
    fetchMarkdownContent(`${process.env.REACT_APP_BASE_API}/career/languages/getMD`, {
      language: localLanguage,
      namespace: `faq_career_${localLanguage}`,
    });
  }, [localLanguage]);

  return (
    <div className={styles.container}>
      <h1>{t('noviopusFaq')}</h1>
      <div className={styles.content}>
        <ReactMarkdown children={markdownContent} />
      </div>
    </div>
  );
};

export default FAQ;
