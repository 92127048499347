import { Box, IconButton, TextField } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useStyles } from './styles';

interface FormTextInputProps extends StandardTextFieldProps {
  labelText?: string;
  placeholder?: string;
  name: any;
  control: any;
  rules?: { required?: boolean; minLength?: number; maxLength?: number };
  disabled?: boolean;
  setValue?: any;
}

const FormTextInput: React.FC<FormTextInputProps> = ({
  labelText,
  name,
  control,
  disabled = false,
  rules = { required: true },
  setValue,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, ref, value, name } }) => (
        <>
          {labelText && <Box className={classes.fieldLabel}>{labelText}</Box>}
          <TextField
            disabled={disabled}
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={clsx(classes.input, {
              [classes.uneditableInput]: disabled,
            })}
            InputProps={{
              endAdornment: !disabled && (
                <IconButton
                  className={clsx(classes.clearIndicator, {
                    [classes.clearIndicatorDirty]: (typeof value === 'number' ? value : value?.length) > 0,
                  })}
                  onClick={() => {
                    setValue(name, typeof value === 'number' ? 0 : '');
                  }}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default FormTextInput;
