import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    input: {
      height: 33,
      '& .MuiInput-root': {
        fontFamily: 'Roboto-Light, Arial, sans-serif',
        fontSize: '0.9rem',
        backgroundColor: theme.input.backgroundColor,
        padding: 0,
      },
      '& .MuiInput-input': {
        padding: '7px 14px',
        [theme.breakpoints.down('sm')]: {
          padding: '7px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '75%',
        },
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 'unset',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'unset',
      },
    },
    inputRtl: {
      height: 33,
      '& .MuiInput-root': {
        fontFamily: 'Roboto-Light, Arial, sans-serif',
        fontSize: '0.9rem',
        backgroundColor: theme.input.backgroundColor,
        padding: 0,
      },
      '& .MuiInput-input': {
        padding: '7px 14px',
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
          padding: '7px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '75%',
        },
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 'unset',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'unset',
      },
      '& .MuiSelect-icon': {
        left: 0,
        right: 'unset',
      },
    },
    menuPaper: {
      maxHeight: 200,
    },
  };
});
