import {
  EducationInterface, EducationItemInterface, SkillName, DegreeName, SchoolName, FieldName,
} from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: EducationInterface = {
  education: [],
  id: '',
  changeCounter: 0,
  form: {},
  skillNames: [],
  degreeNames: [],
  schoolNames: [],
  fieldNames: [],
};

export const educationSlice = createSlice({
  name: 'education',
  initialState,
  reducers: {
    setEducation: (state, action: PayloadAction<EducationInterface>) => {
      state.education = action.payload.education;
      state.id = action.payload.id;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: string | string[] }>) => {
      state.form[action.payload.key] = action.payload.value;
    },
    clearForm: state => {
      state.form = {};
    },
    setEducationItem: (state, action: PayloadAction<{ data: EducationItemInterface }>) => {
      state.education.push(action.payload.data);
    },
    setEducationItemByIndex: (state, action: PayloadAction<{ data: EducationItemInterface }>) => {
      const index = state.education.findIndex(item => item._id === action.payload.data._id);
      if (index !== -1) state.education[index] = action.payload.data;
    },
    removeEducationItemByIndex: (state, action: PayloadAction<{ id: string }>) => {
      const index = state.education.findIndex(item => item._id === action.payload.id);
      if (index !== -1) state.education.splice(index, 1);
    },
    increaseChangeCounter: state => {
      state.changeCounter++;
    },
    clearChangeCounter: state => {
      state.changeCounter = 0;
    },
    setSkillNames: (state, action: PayloadAction<SkillName[]>) => {
      state.skillNames = action.payload;
    },
    setDegreeNames: (state, action: PayloadAction<DegreeName[]>) => {
      state.degreeNames = action.payload;
    },
    setSchoolNames: (state, action: PayloadAction<SchoolName[]>) => {
      state.schoolNames = action.payload;
    },
    setFieldNames: (state, action: PayloadAction<FieldName[]>) => {
      state.fieldNames = action.payload;
    },
  },
});
