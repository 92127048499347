import {
  AppBar,
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

import {
  Add,
  ArrowBack,
  Business,
  ChevronLeft,
  LockOpen,
  Notifications,
  PersonAdd,
  Search,
  Settings,
  Work,
  Clear,
  ExitToApp,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { selectPathName } from 'App/features/Auth';
import { selectCompanies, selectJobNames } from 'App/features/CompaniesAndJobs';
import { SEARCH_JOB_AND_COMPANY_NAMES } from 'App/features/CompaniesAndJobs/constants';
import { selectUserProfile } from 'App/features/Profile';
import { FETCH_USER_PROFILE } from 'App/features/Profile/constants';
import { UserProfileInterface } from 'App/features/Profile/types';
import { selectDirection } from 'App/features/direction/directionSlice';
import { hideNavigationRoutes } from 'App/router/RouterWrapper/constants';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { match } from 'node-match-path';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/useDebounce';
import { authAction } from '../../App/features/Auth/actions';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import LoginForm from './LoginForm';
import styles from './MainNavbar.module.scss';
import NavbarFooter from './NavbarFooter';
import LanguageSelector from './NavbarFooter/LanguageSelector';
import { getSettingsRoutes, menuMobileFirstSection, menuMobileSecondSection } from './constants';
import logoDesktop from './images/Logo_circle_160.png';
import logoMobile from './images/Logo_mobile.png';
import { StyledBadge, useStyles } from './style';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

interface INavbarProps {
  color: 'default' | 'inherit' | 'primary' | 'secondary' | 'transparent';
  isAuth: boolean;
  isOnboard: boolean;
}

function useOutsideHandler(buttonRef, ref, callBackFn) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
        callBackFn(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, buttonRef]);
}

const MainNavbar: React.FC<INavbarProps> = ({ color, isAuth = false, isOnboard = false }) => {
  const [isMobileFormat, setIsMobileFormat] = useState<boolean>(window.innerWidth < 959);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isOptionsMenuOpen, setOptionsMenuOpen] = useState<null | HTMLElement>(null);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputMobileVal, setSearchInputMobileVal] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  const [showLangSelector, setShowLangSelector] = useState(false);
  const [isMobileSearchTyping, setIsMobileSearchTyping] = useState<boolean>(false);
  const jobNames = useAppSelector(selectJobNames);
  const companies = useAppSelector(selectCompanies);
  const userProfile: UserProfileInterface = useAppSelector(selectUserProfile);
  const direction = useAppSelector(selectDirection);
  const userState = useSelector((state: any) => state.auth.authUser);
  const fullName = userState?.firstname && userState?.lastname ? userState?.firstname + ' ' + userState?.lastname : '';

  const pathname = useAppSelector(selectPathName);

  const isHideNavigationRoute = hideNavigationRoutes.some(rout => match(rout, pathname).matches);

  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);
  const searchButtonRef = useRef(null);
  useOutsideHandler(buttonRef, wrapperRef, setIsMenuOpen);
  useOutsideHandler(searchButtonRef, searchRef, () => setIsSearchOpen(false));

  const routes = getSettingsRoutes(isAuth);
  const currentRoute = routes.find(r => match(r.path, pathname).matches);
  const menu: any = currentRoute?.menu;

  const { t } = useTranslation(['mainNavbar', 'navigation']);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: FETCH_USER_PROFILE });
    window.addEventListener('resize', handleWindowResize);
  }, []);

  const handleWindowResize = () => {
    setIsMobileFormat(window.innerWidth <= 959);
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const searchCompaniesAndJobs = value => {
    if (!value) {
      setResults([]);
    } else {
      return dispatch({
        type: SEARCH_JOB_AND_COMPANY_NAMES,
        payload: { name: debouncedSearchValue },
      });
    }
  };

  useEffect(() => {
    searchCompaniesAndJobs(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  console.log(isLoading);

  useEffect(() => {
    setIsLoading(true);
    try {
      let updatedResults: any[] = [];
      if (jobNames) {
        updatedResults = [...updatedResults, ...jobNames];
      }
      if (companies) {
        const uniqueCompanyNames = companies.filter((c, i, arr) => {
          return arr.findIndex(obj => obj.name.toLowerCase() === c.name.toLowerCase()) === i;
        });
        updatedResults = [...updatedResults, ...uniqueCompanyNames];
      }
      setResults(updatedResults);
      console.log();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [jobNames, companies]);

  const handleTitleChange = (e, option) => {
    console.log('handle title change test');
    const location = userProfile.location;
    const selectedType = !option || !option.nameTokens ? 'company' : 'job';
    let searchUrl = `/search-results?type=${selectedType}`;
    if (option) {
      searchUrl += `&name=${option.name}`;
    }
    const { state, country, address } = userProfile.location;
    searchUrl += `&placeId=${location.placeId}&city=${location.city}&state=${
      country === 'United States' ? state : ''
    }&country=${country}&address=${address}`;
    window.location.href = searchUrl;
  };

  const getUserInitials = () => {
    if (userProfile) {
      const { firstname, lastname } = userProfile;
      if (!firstname && !lastname) {
        return '';
      }
      return `${firstname.charAt(0).toUpperCase()} ${lastname.charAt(0).toUpperCase()}`;
    } else return '';
  };

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event.currentTarget);

    setOptionsMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOptionsMenuOpen(null);
  };

  const StyledMenu: any = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      padding: '0px 8px',
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const renderMenuButton = () => {
    return (
      <>
        {/* ------------------ FUTURE DEVELOPING ------------------------------- */}
        {/* <div>
          <IconButton onClick={handleClick} aria-haspopup="true">
            <Avatar className={styles.logoAvatar}>
              <Add />
            </Avatar>
          </IconButton>
          <StyledMenu
            className={styles.subHead}
            anchorEl={isOptionsMenuOpen}
            keepMounted
            open={Boolean(isOptionsMenuOpen)}
            onClose={handleClose}>
            <MenuItem onClick={handleClose} className={styles.dropOptionsBtn}>
              <Add />
              Post
            </MenuItem>
            <MenuItem onClick={handleClose} className={styles.dropOptionsBtn}>
              <Add />
              Page
            </MenuItem>
          </StyledMenu>
        </div>
        <IconButton>
          <StyledBadge badgeContent={2} color="error">
            <Avatar className={styles.logoAvatar}>
              <Notifications />
            </Avatar>
          </StyledBadge>
        </IconButton> */}
        {/* ------------------ FUTURE DEVELOPING ------------------------------- */}
        {isSearchOpen && isMobileFormat ? (
          <IconButton onClick={() => setIsSearchOpen(false)}>
            <Clear />
          </IconButton>
        ) : (
          <IconButton ref={buttonRef} className={styles.navbarRight} onClick={() => setIsMenuOpen(prev => !prev)}>
            {!isMenuOpen ? (
              avatar ? (
                <div className={styles.AvatarWrapper}>
                  <Avatar alt="Profile Picture" src={avatar} className={clsx(styles.logoAvatar, classes.userAvatar)} />
                </div>
              ) : (
                <Avatar className={clsx(styles.logoAvatar, classes.userAvatar)}>{getUserInitials()}</Avatar>
              )
            ) : (
              <IconButton className={styles.AvatarWrapper}>
                <Clear />
              </IconButton>
            )}
          </IconButton>
        )}
        {/* <IconButton ref={buttonRef} className={styles.navbarRight} onClick={() => setIsMenuOpen(prev => !prev)}>
          {avatar ? (
            <Avatar alt="Profile Picture" src={avatar} className={clsx(styles.logoAvatar, classes.userAvatar)} />
          ) : (
            <Avatar className={clsx(styles.logoAvatar, classes.userAvatar)}>{getUserInitials()}</Avatar>
          )}
        </IconButton> */}
      </>
    );
  };

  const openBusinessApp = () => {
    let emailToken = JSON.parse(localStorage.getItem('state') || '{}').auth.authUser.emailToken;
    window.location.href = `${process.env.REACT_APP_BUSINESS_BASE}/dashboard?t=${emailToken}` || '/';
  };

  const handleSignOut = () => {
    setIsMenuOpen(false);
    dispatch({ type: authAction.SIGN_OUT_USER });
  };

  const onClickMenuItem = path => {
    setIsMenuOpen(false);
    dispatch(push(path));
  };

  const getLogoUrl = () => {
    if (isAuth) {
      return '/';
    }
  };

  const handleClear = () => {
    setSearchValue('');
    setSearchInputMobileVal('');
    setIsMobileSearchTyping(false);
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setSearchValue(value);
    setIsMobileSearchTyping(value !== '');
  };

  const avatar = userProfile?.avatar?.urls && userProfile.avatar.urls[2];

  const [IsOpen, setIsOpen] = useState(false);

  const logoContent = (
    <div className={styles.logoGroup}>
      <img src={logoDesktop} alt="logo" className={styles.logoImage} />
      <div className={color === 'primary' ? `${styles.logoText} ${styles.logoTextPrimary}` : styles.logoText}>
        <span className={styles.logoBrand}>{t('noviopus').toUpperCase()}</span>
        <span className={styles.logoTitle}>{t('career')}</span>
      </div>
    </div>
  );

  return (
    <>
      <AppBar color={color}>
        <Toolbar className={classes.toolbar}>
          {/* Left section */}
          <div
            className={`${styles.logoContainer} ${!isMobileFormat ? styles.searchBarMobile : styles.searchBarDesktop}`}>
            {showLangSelector && <LanguageSelector setShowMobileLangSelector={setShowLangSelector} isDesktop />}
            {!isMobileFormat ? (
              <>
                <a href={getLogoUrl() as string} className={styles.logo}>
                  {logoContent}
                </a>
                {!isHideNavigationRoute && (
                  <Autocomplete
                    className={`${styles.search}  ${IsOpen ? classes.autocompleteSelected : classes.autocomplete}  ${
                      direction === 'rtl' && classes.autocompleteRtl
                    } `}
                    options={results}
                    openOnFocus={false}
                    getOptionLabel={option => option.name}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    onChange={handleTitleChange}
                    popupIcon={<Search />}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className={`${IsOpen ? styles.selectedSearchInput : styles.searchInput}`}
                        placeholder={t('searchJobs')}
                        onChange={e => setSearchValue(e.target.value)}
                      />
                    )}
                  />
                )}
              </>
            ) : isSearchOpen ? (
              <>
                <a href={getLogoUrl() as string} className={styles.logo}>
                  <div className={styles.logoGroup}>
                    <img src={logoMobile} alt="logo" className={styles.logoImage} />
                  </div>
                </a>
                <IconButton onClick={() => setIsSearchOpen(false)}>
                  <Search />
                </IconButton>

                <Autocomplete
                  // ref={searchRef}
                  className={clsx(styles.mobileSearch, classes.autocomplete, classes.autocompleteOptionsMobile)}
                  options={results}
                  open={isSearchOpen}
                  openOnFocus={false}
                  getOptionLabel={option => option.name}
                  onOpen={() => setIsOpen(true)}
                  onClose={e => setIsOpen(false)}
                  onChange={handleTitleChange}
                  onInputChange={(event, newInputVal) => {
                    setSearchInputMobileVal(newInputVal);
                  }}
                  inputValue={searchInputMobileVal}
                  disableClearable={true}
                  popupIcon={
                    isMobileSearchTyping ? (
                      <IconButton onClick={handleClear}>
                        <Clear />
                      </IconButton>
                    ) : (
                      <Search />
                    )
                  }
                  // PopperComponent={params => (
                  //   <Popper {...params}>
                  //     {/* <ButtonGroup color="primary" className={styles.btnGrid}>
                  //       <Button color="primary" onClick={() => console.log('asdasd')}>
                  //         {t('Search')}
                  //       </Button>
                  //       <Button color="primary" onClick={() => setIsSearchOpen(false)}>
                  //         {t('Cancel')}
                  //       </Button>
                  //     </ButtonGroup> */}
                  //     {searchValue && params.children}
                  //   </Popper>
                  // )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={clsx(styles.searchInput)}
                      placeholder={t('Search')}
                      onChange={handleInputChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {!results.length && searchValue ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <>
                <a href={getLogoUrl() as string} className={styles.logo}>
                  <div className={styles.logoGroup}>
                    <img src={logoMobile} alt="logo" className={styles.logoImage} />
                  </div>
                </a>

                {isAuth && !isHideNavigationRoute && (
                  <IconButton ref={searchButtonRef} onClick={() => setIsSearchOpen(true)}>
                    <Search />
                  </IconButton>
                )}
              </>
            )}
          </div>

          {/* Right section */}
          <div className={styles.rightGroup}>
            {!isMobileFormat && isAuth && (
              <>
                {isOnboard && !isHideNavigationRoute && (
                  <Button
                    startIcon={<Business className={styles.businessIcon} />}
                    variant="contained"
                    className={styles.businessBtn}
                    onClick={openBusinessApp}>
                    <span className={styles.businessText}>{t('business')}</span>
                  </Button>
                )}
              </>
            )}
            {renderMenuButton()}
          </div>
        </Toolbar>
      </AppBar>

      <div className={`${styles.navbarWrapper}`}>
        <div className={styles.innerDiv} />
        {isMenuOpen &&
          // If the User is authenticated, then show the menu
          (isAuth ? (
            isMobileFormat ? (
              <MenuList ref={wrapperRef} className={`${styles.navbarMenu} ${styles.mobileDropdown} ${styles.mainbar}`}>
                <div className={styles.menuContainer}>
                  {/*First section of the menu */}

                  <div className={styles.LinksWrapper}>
                    <Link
                      to={'/account/profile/edit'}
                      className={styles.menuItem}
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}>
                      {avatar ? (
                        <Avatar
                          alt="Profile Picture"
                          src={avatar}
                          className={clsx(styles.logoAvatar, classes.userAvatar, styles.logoAvatarMobileDrop)}
                        />
                      ) : (
                        <Avatar className={clsx(styles.logoAvatar, classes.userAvatar, styles.logoAvatarMobileDrop)}>
                          {getUserInitials()}
                        </Avatar>
                      )}
                      <Typography variant="body2" className={styles.menuText}>
                        {fullName}
                      </Typography>
                    </Link>

                    {menuMobileFirstSection.map((item, i) => (
                      <Link className={styles.menuItem} key={i} to={item.href}>
                        <ListItemIcon className={styles.IconStyle}>{item.icon}</ListItemIcon>
                        <Typography variant="body2" className={styles.menuText}>
                          {item.title}
                        </Typography>
                      </Link>
                    ))}
                  </div>

                  <div className={styles.BorderWrapper}>
                    <hr className={styles.Border} />
                  </div>

                  {/*Second Section of the menu */}
                  <div className={`${styles.LinksWrapper} ${styles.SecondSection}`}>
                    {menu?.backRoute && (
                      <Link to={menu.backRoute} className={styles.menuItem}>
                        <ListItemIcon className={styles.IconStyleBackRoute}>
                          <ChevronLeft className={styles.backIcon} />
                        </ListItemIcon>
                        <Typography variant="body2" className={styles.menuText}>
                          {menu.backTitle}
                        </Typography>
                      </Link>
                    )}
                    {menu?.links.map((item, i) => (
                      <Link
                        className={styles.menuItem}
                        key={i}
                        to={item.href}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}>
                        <ListItemIcon
                          className={`${styles.IconStyle} ${
                            currentRoute?.path === item.href ? styles.SelectedIcon : ''
                          }`}>
                          {item.icon}
                        </ListItemIcon>
                        <Typography
                          variant="body2"
                          className={`${styles.menuText} ${
                            currentRoute?.path === item.href ? styles.SelectedMenuText : ''
                          }`}>
                          {item.title}
                        </Typography>
                      </Link>
                    ))}
                    {isAuth && (
                      <MenuItem className={styles.logOutBtn} onClick={handleSignOut}>
                        <ListItemIcon className={styles.IconStyle}>
                          <ExitToApp />
                        </ListItemIcon>
                        <Typography variant="body2" className={styles.menuText}>
                          Log Out
                        </Typography>
                      </MenuItem>
                    )}
                  </div>
                  {/* <div className={styles.LinksWrapper}>
                    {menuMobileSecondSection.map((item, i) => (
                      <Link
                        className={styles.menuItem}
                        key={i}
                        to={item.href}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}>
                        <ListItemIcon
                          className={`${styles.IconStyle} ${
                            currentRoute?.path === item.href ? styles.SelectedIcon : ''
                          }`}>
                          {item.icon}
                        </ListItemIcon>
                        <Typography variant="body2" className={styles.menuText}>
                          {item.title}
                        </Typography>
                      </Link>
                    ))}
                  </div> */}

                  {/* {isAuth && (
                    <MenuItem className={styles.menuItem} onClick={() => onClickMenuItem('/')}>
                      <ListItemIcon className={styles.TEST}>
                        <ExitToApp />
                      </ListItemIcon>
                      <Typography variant="body2">Log Out</Typography>
                    </MenuItem>
                  )} */}
                  {/* {menu?.links.map((link, i) => (
                    <MenuItem key={i} className={styles.navbarMenuItem} onClick={() => onClickMenuItem(link.href)}>
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText primary={t(`${link.title}`)} />
                    </MenuItem>
                  ))}
                  {isOnboard && (
                    <MenuItem className={styles.navbarMenuItem} onClick={openBusinessApp}>
                      <ListItemIcon>
                        <Business />
                      </ListItemIcon>
                      <ListItemText primary={t('business')} />
                    </MenuItem>
                  )} */}
                </div>
                <NavbarFooter
                  isAuth={isAuth}
                  onClose={() => setIsMenuOpen(prev => !prev)}
                  handleSignOut={handleSignOut}
                />
              </MenuList>
            ) : (
              <MenuList ref={wrapperRef} className={`${styles.navbarMenu}  ${styles.mainbar}`}>
                <div className={styles.NavDesktopLinks}>
                  {isOnboard && (
                    <MenuItem
                      className={styles.navbarMenuDesktop}
                      onClick={() => onClickMenuItem('/account/profile/edit')}>
                      <ListItemIcon className={styles.IconStyle}>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary={t('settings')} className={styles.menuText} />
                    </MenuItem>
                  )}

                  {isAuth && (
                    <MenuItem className={`${styles.logOutBtn} ${styles.logOutDesktop}`} onClick={handleSignOut}>
                      <ListItemIcon className={styles.IconStyle}>
                        <ExitToApp />
                      </ListItemIcon>
                      <Typography variant="body2" className={`${styles.menuText} ${styles.menuDesktopText}`}>
                        Log Out
                      </Typography>
                    </MenuItem>
                  )}
                </div>

                {/* {isOnboard && (
                  <MenuItem className={styles.navbarMenuItem} onClick={openBusinessApp}>
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                    <ListItemText primary={t('business')} />
                  </MenuItem>
                )} */}

                {/*Third section of the menu */}

                <NavbarFooter
                  isAuth={isAuth}
                  onClose={() => setIsMenuOpen(prev => !prev)}
                  handleSignOut={handleSignOut}
                  setShowLangSelector={setShowLangSelector}
                />
              </MenuList>
            )
          ) : (
            // If the User is not authenticated
            <MenuList ref={wrapperRef} className={`${styles.navbarMenu} ${styles.mainbar}`}>
              {
                <MenuItem className={styles.navbarMenuItem} onClick={() => onClickMenuItem('/search')}>
                  <ListItemIcon>
                    <Work />
                  </ListItemIcon>
                  <ListItemText primary={t('career')} />
                </MenuItem>
              }
              <NavbarFooter
                isAuth={isAuth}
                onClose={() => setIsMenuOpen(prev => !prev)}
                handleSignOut={handleSignOut}
                setShowLangSelector={setShowLangSelector}
              />
            </MenuList>
          ))}
      </div>
    </>
  );
};

export default MainNavbar;
