import React, { useReducer, useEffect, createContext } from 'react';

const initialState = {};

let reducer = (globalState: any, newGlobalState: any) => {
  if (newGlobalState === null) {
    localStorage.removeItem('globalState');
    return initialState;
  }
  return { ...globalState, ...newGlobalState };
};

export const GlobalStateContext = createContext<{
  globalState: any;
  setGlobalState: any;
}>({ globalState: initialState, setGlobalState: null });

const GlobalStateProvider = (props: any) => {
  const storedState = localStorage.getItem('career-app-globalState');
  const localState = storedState && JSON.parse(storedState);

  const [globalState, setGlobalState] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    setGlobalState(globalState);
  }, []);

  useEffect(() => {
    localStorage.setItem('career-app-globalState', JSON.stringify(globalState));
  }, [globalState]);

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>{props.children}</GlobalStateContext.Provider>
  );
};
export default GlobalStateProvider;
