import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../App';

interface LanguageType {
  value: string;
  name: string;
  direction: string;
}

export interface LanguagesListState {
  value: LanguageType[] | undefined;
}

const initialState: LanguagesListState = {
  value: undefined,
};

export const languagesListSlice = createSlice({
  name: 'languagesList',
  initialState,
  reducers: {
    setLanguagesList: (state, action: PayloadAction<LanguageType[]>) => {
      state.value = action.payload;
    },
  },
});

export const { setLanguagesList } = languagesListSlice.actions;

export const selectLanguagesList = (state: RootState) => state.languagesList.value;

export default languagesListSlice.reducer;
