import { push } from 'connected-react-router';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getOnBoardingRedirectUrl, setInformation, setStage, setOnBoarded } from '.';
import { selectIsAuth } from '../Auth';
import { stageAction } from './actions';
import { fetchStage } from './api';

export function* fetchStages() {
  const stage = yield fetchStage();
  if (stage) yield all([put(setStage(stage)), put(setInformation(stage))]);
}

export function* stageSaga() {
  yield takeLatest(stageAction.FETCH_STAGE, checkStageSaga);
}

function* checkStageSaga() {
  const isAuth = yield select(selectIsAuth);
  if (isAuth) {
    yield fetchStages();

    const redirectUrl = yield select(getOnBoardingRedirectUrl);

    if (redirectUrl) {
      yield put(push(redirectUrl));
    } else {
      put(setOnBoarded({ onBoarded: true }));
    }
  }
}
